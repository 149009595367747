$blue-color: #18a0fb;
$blue-light-2-color: #c7e8fe;

$orange-color: #f47f4d;
$orange-light-2-color: #ffe1cc;

$redOrange-color: #fb1e5b;
$redOrange-light-2-color: #ffe2dc;

$purple-color: #6F6CEC;
$purple-light-2-color: #F4F2FF;

$main-color: var(--primary-color);
$secondary-color: var(--secondary-color);

$green-teal-color: #14B7AD;
$green-teal-color-light: #6FD3C9;

.couponInput {
  --primary-color: #{$blue-color};
  --secondary-color: #{$blue-light-2-color};

  &.orange {
    --primary-color: #{$orange-color};
    --secondary-color: #{$orange-light-2-color};
  }
  &.redOrange {
    --primary-color: #{$redOrange-color};
    --secondary-color: #{$redOrange-light-2-color};
  }

  &.purple {
    --primary-color: #{$purple-color};
    --secondary-color: #{$purple-light-2-color};
  }

  &.greenTeal {
    --primary-color: #{$green-teal-color};
    --secondary-color: #{$green-teal-color-light};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 34px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #b2bcc6;
  box-shadow: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  z-index: 999;

  &:hover,
  &.focused {
    border-color: $main-color;
  }

  .multiValue {
    background: $secondary-color;
    border-radius: 4px;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    height: 24px;
    align-items: center;

    .label {
      border-radius: 2px;
      color: #29394d;
      font-size: 12px;
      overflow: hidden;
      padding: 3px;
      padding-left: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      font-weight: 500;
      line-height: 14px;
      text-transform: uppercase;
    }

    .remove {
      align-items: center;
      border-radius: 4px;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
      color: #29394d;
      height: 24px;
      width: 24px;
      justify-content: center;

      &:hover {
        background-color: #ffbdad;
        color: white;
      }
    }
  }
}

.valueContainer {
  position: relative;
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.placeholder {
  position: absolute;
  color: #6d7f92;
  margin-left: 2px;
  margin-right: 2px;
  top: 50%;
  transform: translateY(-50%);
  text-transform: none;
  font-weight: normal;
}

.Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  text-transform: uppercase;
  caret-color: #29394d;

  .text {
    color: #29394d;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: normal;
    text-transform: none;
    white-space: pre;
  }

  input {
    box-sizing: content-box;
    background: 0px center;
    border: 0px;
    font-size: inherit;
    opacity: 1;
    outline: 0px;
    padding: 0px;
    @extend .text;
  }

  .widthCalculator {
    position: absolute;
    top: 0px;
    left: 0px;
    visibility: hidden;
    height: 0px;
    overflow: scroll;
    @extend .text;
  }
}

.actions {
  align-self: stretch;

  .apply {
    opacity: 0;
    visibility: hidden;
    // background: #06d9b1;
    border-radius: 4px;
    display: flex;
    margin: 2px;
    box-sizing: border-box;
    // height: 24px;
    align-items: center;
    cursor: pointer;

    height: 100%;
    border: 1px solid $main-color;
    min-height: calc(100% + 2px);
    margin-right: -1px;
    margin-top: -1px;
    transition: opacity 0.3s ease;

    &.show {
      opacity: 1;
      visibility: visible;

      &.disabled {
        opacity: 0.7;
        cursor: default;
      }
    }

    .label {
      border-radius: 2px;
      // color: #ffffff;
      color: $main-color;
      font-size: 12px;
      padding: 3px;
      padding: 0.5rem;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
      height: 24px;
      width: 24px;
    }

    &:not(.disabled):hover {
      background: $blue-light-2-color;
    }
  }

  .spinner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
}

.errorMessage {
  p {
    font-size: 12px;
    // margin: 0.2rem 0.5rem;
    margin-top: 0.2rem;
    font-weight: normal;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #fe3745;
  }
}
