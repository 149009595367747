@import 'util/util.scss';

.modal_overlay {
  overflow-y: auto;
  display: flex;
}

.modal {
  position: static;
  background-color: #29394d;
  border: none;
  padding: 0;
  bottom: unset;
  min-height: 90vh;
  box-shadow: 0px 0px 10px 20px rgba(41, 57, 77, 0.7);
  z-index: 999;
  max-width: 1046px;
  width: 100%;



  &_wrapper {
    background-color: #29394d;
  }

  &_header {
    position: relative;
    width: 100%;
    padding-top: 34.5%; // image size 882x301
    padding-bottom: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px 5px 0px 0px;
    flex-shrink: 0;


    h1 {
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
      max-width: 616px;
    }

    .chips {
      margin: 20px 0;
      display: flex;

      div {
        margin-right: 3px;
      }

    }

    .speakers {
      display: flex;
      margin-bottom: 24px;

      &_label {
        font-weight: 500;
        font-size: 12px;
        color: #b2bcc6;
      }
    }

    .live_info {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 16px;
    }
  }

  &_details {
    padding: 0 20px;

    .description {
      h2 {
        color: #f1f2f4;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }

      &_content {
        * {
          color: #f1f2f4;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          max-width: 616px;
        }

        button {
          color: #b2bcc6;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }

    .info {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      span {
        color: #b2bcc6;
        font-weight: 400;
      }

      .client_logo {
        width: 58px;
        height: 34px;
        background: #fff;
        border: 1px solid #f1f2f4;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.2rem;

        div {
          background-color: #fff;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
      }

      .info_tags {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  .divider {
    background: #3c4e64;
    height: 1px;
    margin: auto;
    width: calc(100% - 40px);
  }

  .events_list {
    padding: 0 20px;

    h2 {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

// Media queries --------------------------------

@include breakpoint(medium only) {
  .modal {
    margin: 1.5rem;
  }
}

@include breakpoint(medium up) {
  .modal {
    border-radius: 5px;

    &_header {
      padding-left: 46px;
      padding-right: 46px;

      h1 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 16px;
      }

      .chips {
        margin: 20px 0;
        display: flex;

        div {
          margin-right: 3px;
        }

      }

      .playProgress {
        margin: 20px 0;
        display: flex;

        div {
          margin-right: 10px;
        }

      }
      .speakers {
        display: flex;
        margin-bottom: 30px;

        &_label {
          font-size: 14px;
        }
      }
    }

    &_details {
      padding: 34px 46px;

      .description {
        h2 {
          font-size: 16px;
          line-height: 18px;
        }

        &_content {
          * {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      .info {
        padding-left: 70px;
      }
    }

    .divider {
      width: calc(100% - 92px);
    }

    .events_list {
      padding: 34px 46px;

      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

@include breakpoint(large up) {
  //min-width: 1046px;

  .modal {
    margin: auto;
  }

  .modal_overlay {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}