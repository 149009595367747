.actions {
  display: flex;
  align-items: center;
  // height: 34px;

  float: right;
  margin-right: 7px;

  & > * {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .joinButton {
    background: #18a0fb;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;

    &.disabled {
      & > * {
        opacity: 0.7;
        cursor: default;
      }
    }

    &:not(.disabled) {
      &:hover {
        background-color: #2495e1;
      }
    }
  }

  .actionButton {
    position: relative;
    margin-left: 0.5rem;

    background: #06d9b1;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;

    &.removeButton {
      background-color: #fff;
      color: #3c4e64;
      border: 1px solid #b2bcc6;
      padding: 0 12px;
    }

    &:hover {
      & > .tooltip {
        opacity: 1;
        display: flex;
      }
    }
  }

  .joinButton,
  .actionButton {
    padding: 0 1rem;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;

    &.gray {
      background-color: #b2bcc6;

      &:hover {
        background-color: #6d7f92;
      }
    }

    &.blue {
      background: #18a0fb;
    }

    &.green {
      background-color: #06d9b1;
      &:not(.disabled) {
        &:hover {
          background-color: #02af8e;
        }
      }
    }

    span {
      margin-left: 0.5rem;
    }

    &.compact {
      width: 34px;
      padding: 0;

      &.removeButton {
        border: none;
      }

      svg {
        margin: 0;
      }
    }
  }
}

.calendarWrapper {
  background: #ffffff;
  height: 34px;

  button {
    border-color: #b2bcc6 !important;
  }

  a {
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: inherit !important;
    color: #3c4e64 !important;
  }
}

.calendarDropDown {
  border-color: #b2bcc6 !important;
}

.tooltip {
  opacity: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  font-size: 10px;
  color: #29394d;
  padding: 0.5rem;
  transition: 0.3s all ease;

  top: -14px;
  right: 100%;
  margin-right: 5px;
  width: 160px;
  height: 30px;
  border-radius: 3px;
  display: none;

  justify-content: center;
  align-items: center;
}
