.event {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 0.5rem;
  height: 404px;

  &.clickable {
    cursor: pointer;

    &:hover {
      filter: drop-shadow(0px 4px 5px rgba(41, 57, 77, 0.05));
    }
  }

  &.active {
    background: #edfaf5;
    border-color: #dcf5ec;

    .banner .badges {
      .badge {
        background: #edfaf5;
      }
    }
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
    height: 70px;
    margin-bottom: 0.2rem;
  }

  .speakers {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
  }

  .infos {
    margin-top: 0.25rem;
    ul li {
      font-weight: normal;
      font-size: 12px;
      color: #29394d;

      display: flex;
      align-items: center;

      svg {
        flex-shrink: 0;
      }
    }
  }

  .svgStyle {
    position: absolute;
    top: 0;
    right: -4px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .seen {
      position: absolute;
      margin-bottom: 10px;

      div {
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        text-transform: capitalize;
        color: #ffffff;
      }
    }
  }
}

.banner {
  position: relative;
  height: 124px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d7dbe0;
  border-radius: 5px;
  //margin-bottom: 1.5rem;

  .logoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5rem;
  }

  .cycleIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.5rem;
    z-index: 1;
  }

  .cycleDescription {
    position: absolute;
    right: 34px;
    top: 4px;
    font-size: 12px;
    color: #f47f4d;
  }

  .registerBadge {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.25rem;
    width: 36px;
    height: 44px;
  }

  .premiumFlag {
    position: absolute;
    text-align: center;
    width: 200px;
    height: 25px;
    top: 23px;
    right: -70px;
  }
  .badges {
    display: flex;
    margin-left: 0.5rem;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      padding: 0 1rem;
      background: #ffffff;
      border-radius: 5px;
      margin-right: 0.25rem;

      font-size: 12px;
      font-weight: 500;
      color: #29394d;

      &.redBadge {
        background: #fb1e5b !important;
        color: #ffffff;
      }

      &.greenBadge {
        background: #02af8e !important;
        color: #ffffff;
      }
    }

    .inclusPremium {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      padding: 0 1rem;
      background: #ffffff;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 500;
      color: #fb1e5b;
    }
  }

  .labels {
    display: flex;
    margin-left: 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 0.5rem;

    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      padding: 0 0.75rem;
      background: rgb(24, 160, 251);
      border-radius: 50px;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }

      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.logoWrapper {
  width: 58px;
  height: 34px;
  background: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  span {
    background-color: #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
}

.mainActions {
  display: flex;

  a {
    flex: 1;
  }
}

.mainButton {
  padding: 0.4rem 1rem;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-left: auto;
  height: 32px;
  z-index: 11;
  cursor: pointer;

  &:first-child {
    margin-right: 0.25rem;
  }

  &:last-child {
    margin-left: 0.25rem;
  }

  background: #6d7f92;
  border-radius: 5px;

  &:hover {
    background: #29394d;
    color: #fff;
  }

  &.details {
    background: #f1f2f4;
    border-radius: 5px;
    color: #6d7f92;

    &:hover {
      background: #d8dde2;
    }

    &.light {
      background: #ffffff;
    }
  }

  &.blue {
    background: #18a0fb;
    border-radius: 5px;

    &:hover {
      background: #2495e1;
    }
    &:visited,
    &:focus {
      color: #fff;
    }
  }

  &.green {
    background: #06d9b1;
    border-radius: 5px;

    &:hover {
      background: #02af8e;
    }
  }

  &.blue {
    background: #18a0fb;
    border-radius: 5px;

    &:hover {
      background: #2495e1;
    }
  }

  &.red {
    background: #fe3745;
    border-radius: 5px;

    &:hover {
      background: #ff5656;
    }
  }

  &.admin {
    color: #18a0fb;
    background: #ffffff;
    border: 1px solid #18a0fb;

    &:hover {
      background: #f3faff;
    }
  }
}

.strike {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 110%;
    height: 2px;
    border-radius: 2px;
    background: #fe3745;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(-25deg);
  }
}

.fetching {
  filter: none !important;

  h3 {
    height: 1rem;
    width: 5rem;
    background: #e8eaed;
  }
  h6 {
    height: 1rem;
    width: 12rem;
    background: #e8eaed;
  }
}

.fetching_layout2 {
  filter: none !important;
  .banner {
    background: #f1f2f4;
  }

  h2 {
    height: 2rem;
    width: 80%;
    background: #f1f2f4;
  }

  h6 {
    height: 0.8rem;
    width: 50%;
    background: #f1f2f4;
  }

  .infos {
    margin-top: 1.5rem;

    .icon_ph {
      height: 1rem;
      width: 1rem;
      background: #f1f2f4;
      margin-bottom: 10px;
      margin-right: 10px;
    }

    h3 {
      height: 1rem;
      width: 6rem;
      background: #f1f2f4;
      margin-bottom: 10px;
    }
  }
}
