.wrapper {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px 5px;
  max-width: 884px;
  margin: 1.5rem auto;
}

.details_action {
  height: 36px;
  background: #f1f2f4;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;

  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #6d7f92;
  padding: 0.5rem 1.5rem;

  &:hover,
  &:focus {
    background: #d8dde2;
    color: #6d7f92;
  }
}
