$toast-width: 304px;
$toast-background: #ffffff;
$font-color: #999;
$font-size: 1rem;
$animation-duration: 0.75s;

$color-default: #ffffff;
$color-info: #3498db;
$color-success: #07bc0c;
$color-warning: #f1c40f;
$color-error: #f36c56;

$color-progress-default: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);



