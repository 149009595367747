.packsList {
  background: #ffffff;
  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 2rem;
  width: 390px;
  max-height: 486px;
  overflow-y: auto;
}

.packDescription {
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #6d7f92;
  margin: 1rem 3rem 0 3rem;

  span{
    margin-right: 1px;
    margin-left: 1px;
  }
  .counter{
    font-size: 15px;
    font-weight: 500;
  }
}

.packAdd {
  margin: 1rem 0;
  display: flex;
  justify-content: center;

  button {
    margin: 0 1rem;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    cursor: pointer;
    padding: 0.7rem 2rem;
    background: #fff;
    border: 1px solid #6d7f92;
    width: 100%;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #29394d;
    }
    &:hover {
      background-color: #f3f4f4;
    }
  }
}

.title {
  font-size: 16px;
  line-height: 42px;
  color: #29394d;
  margin-left: 1rem;
}

.pack {
  background: #ffffff;
  border: 1px solid #f7953e;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  display: grid;
  margin: 0.5rem auto;
  width: 172px;

  &___header {
    background: #f7953e;
    padding: 0.5rem 0.8rem;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
  }
  &___help {
    svg {
      fill:#FFF !important;
    }
  }

  &___content {
    padding: 0.7rem 0.5rem 0 0.5rem;

    &_dropzone {
      background: #f8f9fa;
      border: 1px dashed #b2bcc6;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      color: #6d7f92;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      p {
        margin: 0;
        font-size: 12px;
        font-weight: normal;
      }
    }

    &_footer {
      line-height: 37px;
      color: #29394d;
      text-align: center;

      span{
        margin-right: 1px;
        margin-left: 1px;
      }
      .x_text {
        font-size: 11px;
        font-weight: 500;
      }
      .s_text {
        font-size: 10px;
        font-weight: normal;
        margin: 0 2px;
      }
    }
  }
}

:global(#simple_pack.drop .onDrop) {
  border: 1.2px dashed #e77510;
  background: #fef0e3;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}