@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.greetings {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.p100 {
  width: 100%;
  height: 100%;
}

.no-max-width {
  max-width: none;
}

// flex
.flex-1 {
  flex: 1;
}

// rotate
// rl90 => rotate left 90deg
.rl90 {
  transform: rotateZ(-90deg);
}
.rr90 {
  transform: rotateZ(90deg);
}
.rl180 {
  transform: rotateZ(-180deg);
}
.rr180 {
  transform: rotateZ(180deg);
}
.rl45 {
  transform: rotateZ(-45deg);
}
.rr45 {
  transform: rotateZ(45deg);
}

.animate-rotate {
  animation: spin 6s linear infinite;
}

.portal-tooltip {
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1) !important;
  font-size: 11px !important;
  color: #29394d !important;
  padding: 0.5rem !important;

  &.show {
    opacity: 1 !important;
  }
}

// image-wrapper

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: normal;

  &.avatar {
    border-radius: 50%;
  }
}

hr {
  &.separator {
    background: #f1f2f4;
    border-color: #f1f2f4;
    margin: 1rem;
  }
}

.vseparator {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: calc(100% - 2rem);
    background: #f1f2f4;
    width: 1px;
    right: 0;
    top: 1rem;
  }

  &.dark {
    &::after {
      background: rgba(109, 127, 146, 0.1);
    }
  }
  &-l {
    &::after {
      right: unset;
      left: 0;
    }
  }
}

.relative {
  position: relative;
}

.strike-price {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 120%;
    height: 2px;
    border-radius: 2px;
    background: #fe3745;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(-25deg);
  }

  &.small {
    &::after {
      width: 80%;
    }
  }
}

.max_2_lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: 1rem; /* fallback */
  max-height: 2rem; /* fallback */
  word-break: break-word;
}

.marker {
  padding: 0;
  background-color: #fcf151;
}

.title-case {
  text-transform: capitalize;
}
