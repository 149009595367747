$avatarSize: 120px;

@mixin responsive($breakpoint) {
  @if $breakpoint == tablet {
    @media only screen and (max-width: 950px) {
      @content;
    }
  }
}

.speaker {
  width: 267px;

  .infos {
    position: relative;
    padding: 0.25rem;
    padding-top: $avatarSize / 2;
    text-align: center;
    color: #29394d;

    a {
      color: #29394d !important;

      &:hover {
        color: #18a0fb !important;
      }
    }

    .star_rating {
      padding-top: 4%;
      padding-bottom: 4%;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        span {
          text-align: center;
          font-size: 27px;
        }
      }
      .on {
        color: #18a0fb;
      }
      .off {
        color: #b2bcc6;
      }
    }

    .avatarWrapper,
    .orgLogo {
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-blend-mode: normal;
      filter: drop-shadow(0px 2px 10px rgba(41, 57, 77, 0.1));
      border-radius: 50%;
    }

    .avatarWrapper {
      position: absolute;
      filter: drop-shadow(0px 2px 10px rgba(41, 57, 77, 0.1));
      border-radius: 50%;
      right: calc(50% - #{$avatarSize / 2});
      top: -60px;
      margin-right: 0;
    }

    .orgLogo {
      background-size: contain;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 44px;
      height: 44px;
      transform: translateX(25%);
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      margin-top: 0.75rem;

      &.fetching {
        background: #e8eaed;
        width: 10rem;
        margin-left: auto;
        margin-right: auto;
      }
    }

    h6 {
      font-weight: normal;
      font-size: 12px;
      margin: 0;

      &.fetching {
        background: #e8eaed;
        width: 6rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .separator {
    background: rgba(109, 127, 146, 0.1);
    border-radius: 2px;
    height: 1px;
    margin: 1rem;
    margin-bottom: 0;
  }

  .description {
    font-weight: normal;
    font-size: 12px;
    color: #6d7f92;
    padding: 1rem;
    transition: all 0.5s;
    overflow: hidden;

    & > * {
      font-weight: normal;
      font-size: 12px;
      color: #6d7f92;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .wrapper {
    display: grid;
    background: rgba(241, 242, 244, 0.5);
    border-radius: 20px;
    height: 190px;
    margin-top: ($avatarSize / 2);

    &.fetching {
      padding-bottom: 100px;
    }
  }

  .detailsButton {
    height: 32px;
    margin: auto 0.5rem 0.5rem 0.5rem;
    padding: 0.4rem 1rem;
    font-weight: 500;
    font-size: 12px;
    background: #f1f2f4;
    border-radius: 5px;
    color: #6d7f92;
    cursor: pointer;

    &:hover {
      &:not([disabled]) {
        background: #d8dde2;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: unset;
    }
  }
}

.single_layout {
  width: 100%;

  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 2rem 0;
    margin-top: 0;
    height: unset;

    @include responsive(tablet) {
      flex-direction: column;
    }

    .infos {
      width: 28%;
      margin: ($avatarSize / 2) 1.7rem 0 1.7rem;

      @include responsive(tablet) {
        width: 100%;
      }
    }

    .separator {
      display: none;

      @include responsive(tablet) {
        display: inline-block;
        width: 70%;
        margin-bottom: 1rem;
      }
    }

    .description {
      width: 72%;
      border-left: 1px solid rgba(109, 127, 146, 0.1);
      border-radius: 2px;
      padding: 0 1.7rem;

      @include responsive(tablet) {
        border: none;
        padding: unset;
        text-align: center;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.cv {
  p {
    display: list-item;
    list-style-type: disc;
  }
}

.speakersList {
  margin-top: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // justify-content: space-between;

  .speakerWrapper,
  .singleSpeakerWrapper {
    margin: 0 0.5rem 1rem 0.5rem;
  }

  .singleSpeakerWrapper {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 18px;
    color: #29394d;
  }
}

.speaker_compact {
  cursor: pointer;
  margin-top: 65px;
  position: relative;
  display: flex;
  flex-direction: column;

  &__avatar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &__infos {
    text-align: center;
    flex: 1;
    padding-top: 65px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    background: #f8f9fa;
    border: 1px solid #f1f2f4;
    border-radius: 5px;
    height: 100%;

    h3 {
      font-weight: 500;
      font-size: 14px;
      color: #29394d;
      margin-bottom: 0;
    }

    h5 {
      font-style: normal;
      font-size: 12px;
      color: #6d7f92;
    }
  }

  &.fetching {
    .infos {
      h3 {
        height: 1rem;
        width: 5rem;
        background: #e8eaed;
      }
      h5 {
        height: 1rem;
        width: 6rem;
        background: #e8eaed;
      }
    }
  }
}
