.toastify__close{
  order: 1;
  padding: 0;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  transition: .3s ease;
  
  display: inline-block;
  cursor: pointer;
  margin: auto;
  border-radius: 50%;
  transform: rotate(45deg);
  
  color: #70707a;
  background: #fff;
  border: none;
  box-shadow: 0 0 0 0.1rem rgba(0,0,0,.06), 0 0.2rem 0.3rem rgba(0,0,0,.06);
  width: rem-calc(32px);
  height: rem-calc(32px);
  line-height: rem-calc(32px);  
  opacity: 0.95;
  
  position: absolute;
  top: 10px;
  right: 10px;
  
  &:hover, &:focus {
    opacity: 1;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,.12), 0 0.2rem 0.3rem rgba(0,0,0,.06);
    color: #686871;    
  }
  
  &:after{
    content: " ";
    position: absolute;
    left: 50%;
    height: 50%;
    transform: translateY(50%);
    border-left: 1px solid #70707a;
  }

  &:before{
    content: " ";
    position: absolute;
    top: 50%;
    width: 50%;
    transform: translateX(-50%);
    border-top: 1px solid #70707a;
  }  
}
