@media (max-width: 825px) {
  button{
    min-width: unset !important;
  }

  .form {
    margin-left: 7px !important;

    & > div {
      width: 9vh;
      font-size: 13px;
    }

  .button {
    margin-left: 2px !important;
    & > button {
      width: 72px;
    }
  }

  }
}

.form{
  display: flex;
  position: absolute;
  margin-left: 15rem;
  margin-top: -2.75rem;

  .button{
    margin-left: 15px;
    margin-top: 26px;
  }

}
