.ttp-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	outline: none;
	padding: 0px;
	transform: translate(-50%, -50%);
	background: #F8F9FA;
	box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
	border-radius: 5px;

	&__wrapper {
		max-width: 860px;
		min-width: 600px;
	}

	&__header {
		padding: 1rem 2rem;
		background: #F3FAFF;
		border-radius: 5px 5px 0px 0px;
		border-bottom: solid 1px #C7E8FE;
		position: relative;

		.content {
			position: relative;
			z-index: 1;
			font-size: 22px;
			line-height: 26px;
			color: #2495E1;

			&.center {
				text-align: center;
			}
		}

		.icon-wrapper {
			position: absolute;
			width: 90px;
			height: 90px;
			border-radius: 50%;
			background: #F3FAFF;
			border: 0.5px solid #C7E8FE;
			// box-sizing: border-box;
			font-size: 1.4rem;
			color: #2495E1;


			bottom: -45px;
			left: calc(50% - 45px);
			transform: rotate(45deg);
			border-left-color: transparent !important; /* !important to keep border invisible, when updating border-color */
			border-top-color: transparent !important;

			& > div {
				transform: rotate(-45deg);
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				padding-bottom: 1.2rem;
			}
		}

		&.alert {
			background: #FFF5F6;
			border-color: #FFDBDD;
			display: flex;
			justify-content: center;

			.content {
				color: #F4515D;
			}

			.icon-wrapper {
				background: #FFF5F6;
				color: #F4515D;
				border-color: #FFDBDD;
			}
		}

		&.success {
			background: #F1FBF9;
			border-color: #C1EFDF;

			.content {
				color: #02AF8E;
			}

			.icon-wrapper {
				background: #F1FBF9;
				color: #02AF8E;
				border-color: #C1EFDF;
			}
		}
	}

	&__content {
		.message {
			font-size: 18px;
			line-height: 21px;
			color: #29394D;
			padding: 1rem 2rem;
			text-align: center;
		}
	}

	&__footer {
		border-top: 1px solid #F1F2F4;
		padding: 1.5rem 2rem;
		text-align: center;

		.main-action {
			min-width: 295px;
		}
	}

	&__close {
		position: absolute;
		top: -15px;
		right: -15px;
		background: #FFFFFF;
		border: 1px solid #F1F2F4;
		box-sizing: border-box;
		width: 36px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		font-size: 1.6rem;
		color: #3C4E64;
		cursor: pointer;
	}

	&.ReactModal__Content--after-open {
		transform-origin: left top; /* To cancel translation transition and focus on scale */
		transform: translate(-50%, -50%) scale(1);
		transition: transform .3s ease-in-out;
	}
}
