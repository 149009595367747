@import 'styles/base/colors';

$colors: (
  slateGrey: (
    default: $slateGrey,
    text: $white,
  ),
);

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0 13px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  @each $color, $variants in $colors {
    &.#{$color} {
      background-color: map-get($variants, 'default');
      color: map-get($variants, 'text');

      &.transparent {
        background-color: rgba(map-get($variants, 'default'), 0.7);
      }

      &:hover{
        background-color: rgba(map-get($variants, 'default'), 1);
        cursor: pointer;
      }
    }
  }
}
