@import 'util/util.scss';

.tagsNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  flex-wrap: wrap;
  position: relative;
  // z-index: 2;

  .period_tags {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }

  .tag {
    width: max-content !important;
    background: #6d7f92;
    border: 0 solid #f1f2f4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 30px;
    margin-right: 0.4rem;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 9px 17px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    margin-bottom: 0.5rem;

    &.active {
      background: #18a0fb;
      border-radius: 20px !important;
      color: #ffffff !important;

      &:hover{
         background: #18a0fb;
      }
    }

    &:hover{
      background: rgb(109, 127, 146);
    }
  }

  .separator {
    background-color: #b2bcc6;
    width: 1px !important;
    height: 30px;
  }

  .fetching {
    height: 32px;
    width: 140px !important;
  }
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  // flex-wrap: wrap;

  .btn_plus {
    flex: 20%;
    margin: 0 2.5rem;
    justify-content: center;
    width: 230px;
    height: 32px;
    background: none !important;
    // border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6d7f92;
    padding-top: 8px;
    display: inline-flex;
    cursor: pointer;
    div {
      margin-left: 10px;
    }

    &:hover {
      background-color: #f1f2f4;
    }
  }
}

// ------ Media Queries :
@include breakpoint(medium) {
  .tagsNavigation {
    .period_tags {
      flex-basis: unset;
    }
  }
}
