.option_wrapper {
  max-width: 832px;
  position: relative;
  overflow: hidden;
}

.option_content {
  padding: 0.7rem;
  background: #f8f9fa;
  border: 1px solid #d6dbe0;
  border-radius: 5px;

  .option_image {
    border-radius: 5px;
    height: 120px;
    width: 100%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .priceOff {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 80%;
      height: 2px;
      border-radius: 5px;
      background: #fe3745;
      top: 50%;
      left: 10%;
      transform: rotate(-20deg);
    }
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #29394d;
  }

  div {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #29394d;

    &.custom_details {
      overflow: hidden;
      height: 20px;

      :first-child {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .more_details {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    cursor: pointer;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background: #f1f2f4;
    border: 2px solid #b2bcc6;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
    transition: 300ms all ease;

    &.checked {
      border-color: #18a0fb;
      background: #18a0fb;
    }

    input {
      display: none;
    }
  }

  &.option_active {
    border-color: #18a0fb;
    background: #f3faff;
  }

  &.option_sold_out {
    opacity: 0.5;
  }

  &:not(.option_sold_out) {
    &:hover {
      border-color: #18a0fb;
      background: #f3faff;

      .checkbox {
        border-color: #18a0fb;
      }
    }
  }
}

.sold_out {
  height: 20px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fe3745;

  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #fff;

  position: absolute;
  transform: rotate(45deg);
  width: 84px;
  top: 13px;
  right: -20px;
}
