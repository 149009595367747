@import 'styles/base/variables';

.wrapper {
  position: relative;

  .cart_btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3c4e64;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;

    &:disabled {
      cursor: unset;
      opacity: 0.6;
    }

    .badge {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: -9px;
      background: #fb1e5b;
      border-radius: 50%;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      width: 18px;
      height: 18px;
    }

    .label {
      font-size: 1rem;
      font-weight: 500;
      margin-left: 1rem;
    }

    &.expanded {
      justify-content: flex-start;
    }
  }

  .cart_content {
    position: absolute;
    top: $subMenuHeight;
    right: 0;
    min-width: 440px;
    max-height: calc(100vh - #{$subMenuHeight} - #{$topBarMenuHeight} - 2rem);
    overflow-y: auto;
    padding: 1.9rem 1.2rem;
    margin-top: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 30px rgba(41, 57, 77, 0.3);
    border-radius: 5px;

    hr {
      border-bottom-color: #d8dde2;

      &:last-child {
        display: none;
      }
    }

    .empty_cart {
      text-align: center;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      color: #6d7f92;
    }
  }

  &:hover {
    .cart_btn:not(:disabled) {
      background-color: #c7e8fe;
      svg * {
        fill: #29394d;
      }

      .label {
        color: #29394d !important;
      }
    }
  }
}
