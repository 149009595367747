.help_toolkit {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    border-radius: 5px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  &__container {
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    color: #29394d;
    padding: 1rem;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__content {
    font-weight: normal;
    font-size: 12px;
  }
}
