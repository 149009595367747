.noData {
  padding: 1rem;
  .iconWrapper {
    height: 80px;
    width: 80px;
    background: #ffffff;
    border: 1px solid rgba(178, 188, 198, 0.5);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    margin-bottom: 1rem;
  }

  h5 {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #6d7f92;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #6d7f92;
  }
}
