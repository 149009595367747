.submit {
  &.orange {
    background-color: #f47f4d;

    &:not(.disabled):hover {
      background: #f47f4d;
    }
  }

  &.redOrange {
    background-color: #fb1e5b;

    &:not(.disabled):hover {
      background: #fb1e5b;
    }
  }

  &.purple {
    background-color: #6F6CEC;

    &:not(.disabled):hover {
      background: #5F5DE8;
    }
  }

  &.orangeRoyal {
    background-color: #f7953e;

    &:not(.disabled):hover {
      background: #e68443;
    }
  }

  &.greenTeal {
    background-color: #14B7AD;

    &:not(.disabled):hover {
      background: rgb(43, 151, 140);
    }
  }

  padding: 0.9rem 1rem;
  background: #18a0fb;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 100;

  span {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    // margin-right: 0.5rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:not(.disabled):hover {
    background: #2495e1;
  }
}
