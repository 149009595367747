.eventLayoutHover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(41, 57, 77, 0.58);
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  pointer-events: none;

  .tagsAddButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #18a0fb;
    border-radius: 2.5rem;
    width: 2.7rem;
    height: 2.7rem;
    color: white;
    border: none;
    margin-top: 0.6rem;
    font-size: 1.6rem;
    cursor: pointer;
    pointer-events: all;
    &:hover {
      background: #3b8ad5;
    }
    padding-left: 0.1rem;
  }
}
