.TagsForm {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 3.5rem;
  margin-bottom: 0;
  width: calc(100% - 1rem);
  height: calc(100% - 4.5rem);
  z-index: 14;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    display: flex;
    margin-bottom: 0.8rem;

    .headerTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #29394d;
      flex: 1;
      padding-top: 0.1rem;
    }
    .close_icon {
      text-align: end;
      svg {
        cursor: pointer;
      }
    }
  }

  .mainContainer {
    position: relative;
    width: 100%;
    height: calc(100% - 1.5rem);
    .tagAndSelectContainer {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      .selectContainer {
        padding: 0.08rem;
        white-space: nowrap;
      }
      .tagsSelectContainer {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #6d7f92;
      }

      .action {
        display: block;
        text-align: center;
        margin-top: 0.7rem;
        .submitBtn {
          border: none;
          outline: none;
          height: 2.75rem;
          border-radius: 5px;
          font-weight: 500;
          font-size: 0.9rem;
          width: 70%;
          height: 32px;
          background: #06d9b1;
          border-radius: 5px;
          color: #ffffff;

          &:hover {
            cursor: pointer;
            background: #02af8e;
          }
        }
      }

      .tagsContainer {
        display: flex;
        flex-direction: row;
        margin-top: 0.7rem;
        flex-wrap: wrap;
      }
    }
    .tagAndSelectContainer::-webkit-scrollbar {
      width: 8px;
    }

    .tagAndSelectContainer::-webkit-scrollbar-track {
      background: #f3f3f3;
      border-radius: 20px;
    }

    .tagAndSelectContainer::-webkit-scrollbar-thumb {
      background-color: #18a0fb;
      border-radius: 20px;
      border: 1.5px solid #f3f3f3;
    }

    .loader {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    .saving {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      z-index: 15;
      align-items: center;
      justify-content: center;
      background-color: rgba(211, 230, 255, 0.58);
      border-radius: 5px;
    }
  }
}
