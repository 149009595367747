@import 'styles/base/colors';

$sizes: small, medium, large;

$colors: (
  blue: (
    default: $blue,
    hover: $blue-hover,
    text: $white,
    textHover: $white,
  ),
  coralRed: (
    default: $coralRed,
    hover: $coralRed-hover,
    text: $white,
    textHover: $white,
  ),
  redOrange: (
    default: $redOrange,
    hover: $redOrange-hover,
    text: $white,
    textHover: $white,
  ),
  greenTeal: (
    default: $greenTeal,
    hover: $greenTeal-hover,
    text: $white,
    textHover: $white,
  ),
  slateGrey: (
    default: $slateGrey,
    hover: $slateGrey-hover,
    border: $white,
    text: $white,
    textHover: $white,
  ),
  white: (
    default: $white,
    hover: $slateGrey,
    text: $slateGrey,
    textHover: $white,
  ),
  orange: (
    default: $orange,
    hover: $orange-hover,
    text: $white,
    textHover: $white,
  ),
);

$padding: (
  small: 10px 16px,
  medium: 12px 28px,
  large: 14px 36px,
);

$padding-icon: (
  small: 10px,
  medium: 13px,
  large: 16px,
);

$min-width: (
  small: 112px,
  medium: 150px,
  large: 153px,
);

$min-height: (
  small: 35.68px,
  medium: 44px,
  large: 44px,
);

$font-size: (
  small: 14px,
  medium: 16px,
  large: 20px,
);

$icon-margin: (
  small: 7px,
  medium: 10px,
  large: 13px,
);

// ----------------------------

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  //margin: 0.5rem;

  &.full_width {
    width: 100%;
  }

  .title {
    font-weight: 500;
  }

  .icon {
    display: flex;
    align-items: center;
  }

  // Size --------------

  @each $size in $sizes {
    &.#{$size} {
      min-width: map-get($min-width, $size);
      min-height: map-get($min-height, $size);
      padding: map-get($padding, $size);

      .title {
        font-size: map-get($font-size, $size);
      }

      .icon {
        svg {
          width: map-get($font-size, $size);
          height: auto;
        }
        &:first-child {
          margin-right: map-get($icon-margin, $size);
        }
        &:last-child {
          margin-left: map-get($icon-margin, $size);
        }
      }

      &.icon_only {
        min-width: unset;
        padding: map-get($padding-icon, $size);
        .icon {
          margin: unset;
        }
      }
    }
  }

  // Variants with color -------

  @each $color, $variants in $colors {
    &.#{$color} {
      .icon {
        &.customized > svg * {
          fill: map-get($variants, 'text');
        }
      }

      &.contained {
        background-color: map-get($variants, 'default');
        .title {
          color: map-get($variants, 'text');
        }
        &:hover {
          background-color: map-get($variants, 'hover');
          .title {
            color: map-get($variants, 'textHover');
          }
        }
      }

      &.outlined {
        border: 1px solid map-get($variants, 'default');
        .title {
          color: map-get($variants, 'text');
        }
        &:hover {
          background-color: map-get($variants, 'default');
        }
      }

      &.WatchGrey {
        border: 1px solid map-get($variants, 'border');
        .title {
          color: map-get($variants, 'text');
        }
        &:hover {
          border: 1px solid map-get($variants, 'default');
          background-color: map-get($variants, 'default');
        }
      }

      &.outlinedInverse {
        border: 1px solid map-get($variants, 'default');
        .title {
          color: map-get($variants, 'default');
        }
        &:hover {
          background-color: map-get($variants, 'hover');
          border-color: map-get($variants, 'hover');
        }
      }
    }
  }
}
