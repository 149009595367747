
.event_banner{
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 1rem;
  height: 340px;
}

.logo {
  width: 271px;
  height: 209px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/img/new_fff_2022.png');
}

.details {
  flex: 1;
  //margin-left: 2rem;
  //max-width: 440px;

  .live{
    color: #FE3745;
    font-weight: 700;
    font-size: 25px;
    line-height: 50px;
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    color: #29394D;
    margin-bottom: 0.5rem;

  }

  .subTitle {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  ul li {
    font-weight: normal;
    font-size: 12px;
    color: #29394D;
    margin-bottom: 0.25rem;

    display: flex;
    align-items: center;
  }

}

.premiumFlag{
  position: absolute;
  text-align: center;
  width: 200px;
  height: 25px;
  top: 33px;
  right: -61px;
}

.active {
  background: #edfaf5;
  border-color: #dcf5ec;

  .banner .badges {
    .badge {
      background: #edfaf5;
    }
  }
}


.actions {
  display: flex;
  align-items: center;

  &:not(.fullWidth) {
    & > * {
      width: 33.33%;
    }
  }

  /*&.fullWidth {
    & > * {
      flex: 1;
      width: 183px;
    }
  }*/

  a {
    background: #f1f2f4;
    border-radius: 5px;
    font-size: 12px;
    color: #6d7f92;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    float: left;
    margin: 3px;
    min-width: 140px;

    &:hover {
      background: #d8dde2;
    }

    &.green {
      background: #06d9b1;
      color: #fff;

      &:hover {
        background: #02af8e;
      }
    }

    &.red {
      background: #fe3745;
      border-radius: 5px;

      &:hover {
        background: #ff5656;
      }
    }

    &.blue {
      background: #18A0FB;
    }
  }

  .price {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-transform: lowercase;
    color: #29394D;
    margin-right: 3%;

  }
}

$avatarSize: 40px;

@mixin responsive($breakpoint) {
  @if $breakpoint == tablet {
    @media only screen and (max-width: 950px) {
      @content;
    }
  }
}


.speaker_bloc{
  flex: 1;
  //margin-left: 25%;
  //max-width: 215px;

  .speaker_wrapper {
    padding-top: 20% !important;
    padding-left: 12% !important;
  }

  .speaker {
    width: 40px;
    height: 40px;
    background: rgba(60, 78, 100, 0.7);
    backdrop-filter: blur(4px);
    border-radius: 5px;

    .infos {
      position: absolute;
      padding: 0.25rem;
      padding-top: $avatarSize / 2;
      text-align: center;
      color: #29394d;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      .avatarWrapper,
      .orgLogo {
        border: 3px solid #ffffff;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-blend-mode: normal;
        filter: drop-shadow(0px 2px 10px rgba(41, 57, 77, 0.1));
        border-radius: 50%;
      }

      .avatarWrapper {
        position: absolute;
        filter: drop-shadow(0px 2px 10px rgba(41, 57, 77, 0.1));
        width: $avatarSize;
        height: $avatarSize;
        border-radius: 50%;
        right: calc(50% - #{$avatarSize / 2});
        top: -13px;
      }

      .orgLogo {
        background-size: contain;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 44px;
        height: 44px;
        transform: translateX(25%);
      }

      h5 {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        margin-top: 3.75rem;

      }

      h6 {
        font-weight: normal;
        font-size: 12px;
        margin: 0;
      }
    }

    .wrapper {
      display: grid;
      border-radius: 20px;
      margin-top: ($avatarSize / 2);
    }

  }
}

.banner {
  position: relative;
  height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d7dbe0;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  overflow: hidden;


  .logoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.5rem;
  }

  .registerBadge {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.25rem;
    width: 36px;
    height: 44px;
  }

  .badges {
    display: flex;
    margin-left: 0.5rem;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      padding: 0 1rem;
      background: #ffffff;
      border-radius: 5px;
      margin-right: 0.25rem;
      font-size: 12px;
      font-weight: 500;
      color: #29394d;
    }

    .inclusPremium {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      padding: 0 1rem;
      background: #ffffff;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 500;
      color: #FB1E5B;
    }

  }

  .labels {
    display: flex;
    margin-left: 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 0.5rem;

    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      padding: 0 0.75rem;
      background: rgb(24, 160, 251);
      border-radius: 50px;
      font-size: 12px;
      font-weight: 400;
      color: #fff;


      &:not(:last-child) {
        margin-right: 0.25rem;
      }

    }

  }
}

.timeWrapper{
  width: fit-content;
  max-width: 100px;
  height: fit-content;
  border: 1px solid #D8DDE2;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 18px;
  text-align: center;

  &.text{
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-transform: lowercase;
  }

}




