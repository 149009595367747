#top-footer {
  background-color: $new-title-color;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  color: $new-light-gray;
  .footer-log {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.75rem 1rem;
    border-right: 1px solid $new-gray;
  }
  .footer-social {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.25rem 1rem;
    border-right: 1px solid $new-gray;
    li {
      width: 2rem;
      height: 2rem;
      color: $new-title-color;
      border-radius: 50%;
      background-color: $new-light-gray;
      font-weight: bold;
      margin: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .footer-desc {
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0 2rem;
    color: $new-light-gray;
    span {
      font-weight: 600;
      margin-right: 0.4rem;
    }
    p {
      margin: 0;
    }
  }
}
#bottom-footer {
  background-color: $new-main-color;
  color: $new-light-gray;
  padding: 1rem 0;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 400;
}
#footer-content {
  padding: 1px 0;
  background: #f1f2f4;
}
