#select-community {
  width: 100%;
  min-height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/img/select-community-bg.jpg') no-repeat center center fixed;
  background-size: cover;

  .sc__container {
    width: 100%;
    min-height: 100%;
    padding: 6rem;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sc__logo {
    width: rem-calc(128px);
    height: auto;
  }

  .sc__title {
    color: #ffffff;
    margin: 2rem;
    margin-bottom: 4rem;
    font-size: 20px;
  }

  .sc__communities {
    justify-content: center;
    width: 100%;
  }
}
.sc__community {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba($main-blue, 0);
    transition: nth($transitions, 3);
  }

  &:hover {
    &::before {
      background-color: rgba($main-blue, 0.7);
      transition: nth($transitions, 3);
    }
  }

  &-logo {
    width: 100%;
    height: rem-calc(160px);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
#has__owner {
  .sc__container {
  }
  .sc__client,
  .sc__member {
    min-height: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    padding: 6rem 2rem;
  }
  h2 {
    font-family: $h-font-family;
    margin: 1rem 0 4rem;
    font-size: 1.5rem;
  }
  i {
    font-size: 2rem;
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid;
  }
  .sc__com-contains {
    width: 100%;
    justify-content: center;
  }
  .sc__client {
    color: $portal-color;
  }
  .sc__member {
    background-color: $portal-color;
    background: linear-gradient(45deg, $portal-blue, $portal-color 60%);
    color: #fff;
  }
  .sc__logo {
    width: 100%;
    text-align: center;
    position: absolute;
    padding-top: 2rem;
    img {
      width: rem-calc(128px);
      margin-left: 13px;
    }
  }
  .sc__community {
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
      0 1px 4px rgba(0, 0, 0, 0.08);
    border: none;
    color: $portal-color;
    font-family: $h-font-family;
  }
  .sc__multi {
    width: 100%;
  }
}
