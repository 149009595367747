.sessionModal {
  padding: 1.5rem;
  background: #fff;

  .pictureBox {
    position: relative;
    padding-right: 1rem;

    .label {
      margin-left: 1rem;
      position: absolute;
      top: 0;
      left: 0;

      width: 63.57px;
      height: 19px;

      background: #ffffff;
      border-radius: 50px;
      transform: translateY(-50%);

      text-align: center;
      font-weight: normal;
      font-size: 10px;
      color: #3c4e64;
    }

    .logo {
      position: relative;
      // height: 182px;
      // min-width: 350px;
      max-height: 234px;
      padding-bottom: 48.5%;
      border-radius: 5px;
      background: linear-gradient(
        180deg,
        rgba(41, 57, 77, 0) 0%,
        rgba(41, 57, 77, 0.279) 48.96%,
        rgba(41, 57, 77, 0.81) 100%
      );
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

      .logoPartner {
        position: absolute;
        left: 0;
        top: 0;
        margin: 1rem;
        width: 100px;
        height: 50px;
        background: #fff;
        border: 1px solid #f1f2f4;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.1rem 0.5rem;

        span {
          background-color: #fff;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
      }

      .soldOut {
        position: absolute;
        background: #f7953e;
        transform: rotate(45deg);
        height: 18px;
        width: 100px;
        right: -22px;
        top: 19px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 500;
        font-size: 10px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }

  .sessionTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #29394D;
  }

  .sessionHeader {
    display: flex;
    align-items: center;
    margin: 1rem 0;

    .block {
      display: flex;
      align-items: center;

      span {
        font-weight: normal;
        font-size: 12px;
        color: #29394d;

        &.sessionTime {
          color: #29394d;
        }
      }
    }
    .separator {
      margin: 0 1rem;
      color: #b2bcc6;
    }
  }

  .block {
    display: flex;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 12px;
      color: #6d7f92;

      &.sessionTime {
        color: #3c4e64;
      }
    }
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
    margin-bottom: 0.2rem;
  }

  .description {
    max-height: 280px;
    font-weight: normal;
    margin: 1rem 0;
    font-size: 14px;
    line-height: 16px;
    color: #29394d;
    height: 100%;
    overflow-y: scroll;
  }
}

.speakers {
  max-height: 250px;
  overflow-y: auto;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .avatar {
    width: 50px;
    height: 50px;
    border: 2px solid #ffffff;
    background-color: #a7aeb6;
    border-radius: 50%;
    background-size: cover;
    background-position: center;

    &:global(.empty-avatar) {
      span {
        font-size: 1rem;
      }
    }
  }

  .avatarName {
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #29394d;
  }

  .profession {
    text-align: center;
    margin-top: 0.2rem;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #6d7f92;
  }
}

.docs {
  background: #f8f9fa;
  padding: 1rem;

  .docsList {
    display: flex;
    overflow-y: auto;
  }

  .dowWrapper {
    background: #ffffff;
    border: 1px dashed #b2bcc6;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    max-width: 280px;
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
      .downloadIcon {
        opacity: 1;
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      flex-shrink: 0;

      img {
        height: 25px;
        width: 25px;
      }
    }

    .title {
      font-weight: 500;
      font-size: 12px;
      color: #29394d;
    }
    .downloadIcon {
      margin: 0 0.25rem;
      margin-left: auto;
      flex-shrink: 0;
      opacity: 0;
      transition: 0.3s all ease;
    }
  }
}
