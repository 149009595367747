
.toastify-content {

  padding: 0 rem-calc(11px);
  margin-bottom: 0.5rem;
  min-height: rem-calc(50px);  
  color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,.15);
  border-radius: rem-calc(60px) / 2;
  font-size: initial;
  height: auto;
  max-height: none;
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: flex-start;

  position: relative;
  width: 100%;
  background: $toast-background;

  &--default {

  }
  &--info {
    border-left: 6px solid $color-info;
    background-color: $color-info;
    color: #fff;
  }
  &--success {
    border-left: 6px solid $color-success;
    background-color: $color-success;
    color: #fff;
  }
  &--warning {
    border-left: 6px solid $color-warning;
    background-color: $color-warning;
    color: #fff;
  }
  &--error {
    border-left: 6px solid $color-error;
    background-color: $color-error;
    color: #fff;
  }
}

.toastify__body {
  font-size: $font-size;
  color: black;
  padding: rem-calc(16px) rem-calc(70px) rem-calc(16px) rem-calc(90px);
  
  ul {
    list-style-type: disc;
  }
}
