.wrapper {
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0px;
  padding-right: 35px;
  text-transform: none;

  p {
    font-size: 1.75rem;
    font-weight: 300;
    color: #a94442;

    a {
      font-size: 1.75rem;
      font-weight: 400;
      color: #a94442 !important;
      text-decoration: underline;
    }
  }
}

.close_button {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
