.liveIcon {
  width: 18px;
  height: 18px;
  position: relative;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ff636e 0%,
    rgba(255, 99, 110, 0) 100%
  );
  border-radius: 50px;

  &::after {
    content: '';
    display: block;
    width: 65%;
    height: 65%;
    border-radius: 50%;
    background: #fe3745;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
