/* 
  - These are the colors used in the application. 
  - The variable names are in this form : {colorName}-{variant}
  - New variants may be added. (Please make sure that the naming convention is respected)
*/

// Blue
$blue: #18a0fb;
$blue-hover: #1486d2;

// Orange
$orange: #f7953e;
$orange-hover: #c67732;

//red
$red: #fe3745;
$red-hover: #eb2535;

// RedOrange
$redOrange: #fb1e5b;
$redOrange-hover: #cc1c4d;

// CoralRed
$coralRed: #fe3745;
$coralRed-hover: #ec1a28;

// GreenTeal
$greenTeal: #06d9b1;
$greenTeal-hover: #02af8e;

// SlateGrey
$slateGrey: #6d7f92;
$slateGrey-hover: #5a6673;

// White
$white: #fff;
$white-hover: #f1f2f4;
