.personalDataWrapper {
  & > div:first-child {
    border: none;
  }
}

.personalDataActions {
  position: absolute;
  background: #fff;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 10px rgba(41, 57, 77, 0.1);
  z-index: 100;
  border-radius: 0px 0px 5px 5px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}
