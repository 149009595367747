@import 'util/util.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.card_wrapper {
  position: relative;
  width: 260px;
  height: 273px;

  &.full_width {
    width: 100%;
  }

  &:hover {
    filter: drop-shadow(0px 0px 30px rgba(60, 78, 100, 0.8));
  }

  .card {
    cursor: pointer;
    background: #3c4e64;
    border-radius: 5px;
    width: 100%;
    height: 100%;

    &_header {
      height: 62.5%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; // Change it to space-between when activating top section
      padding: 0.7rem;
      height: 170px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px 5px 0px 0px;

      & > div {
        display: flex;
        justify-content: space-between;
      }

      .playProgress {
        margin-top: 10px;
      }

      .logoWrapper {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0.5rem;
      }

      .trendBadge {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0.25rem;
        width: 36px;
        height: 44px;

        .trending {
          //display: flex;
          margin: 5px 2px;
          position: absolute;

          h3 {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #ffffff;
            align-items: center;
            margin-bottom: auto;
          }

          h2 {
            font-weight: 700;
            font-size: 15px;
            line-height: 12px;
            color: #ffffff;
            text-align-last: center;
          }
        }
      }
    }

    &_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 35%;
      padding: 0.7rem;

      .title,
      .speakers {
        margin-bottom: 0.38rem;
      }

      .title {
        color: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }

      .info {
        .speakers {
          color: #b2bcc6;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
        }

        .live_info {
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    &_footer {
      display: none;
      opacity: 0;
      background-color: #3c4e64;
      border-radius: 0px 0px 5px 5px;
    }
  }
}

.logoWrapper {
  width: 58px;
  height: 34px;
  background: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;

  span {
    background-color: #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
}

.card_fetching_wrapper {
  width: 260px;
  height: 273px;
  background-color: #3c4e64;

  .card_header {
    height: 170px;
    background-color: #29394d;
    opacity: 0.7;
  }

  .card_content {
    padding: 0.7rem;

    .title,
    .speakers,
    .live_info {
      background-color: #29394d;
      opacity: 0.7;
    }

    .title {
      height: 1.5rem;
      width: 70%;
      margin-bottom: 0.7rem;
    }

    .speakers {
      height: 1rem;
      width: 40%;
      margin-bottom: 0.7rem;
    }

    .live_info {
      height: 1rem;
      width: 80%;
    }
  }
}

// ------ Media Queries :

@include breakpoint(medium) {
  .card_wrapper {
    width: 305px;
    height: 320px;

    .card {
      &_header {
        height: 62.5%;
      }

      &_content {
        height: 35%;

        .title,
        .speakers {
          margin-bottom: 0.7rem;
        }
        .title {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .card_fetching_wrapper {
    width: 305px;
    height: 320px;

    .card_content {
      .title {
        height: 1.8rem;
        width: 70%;
        margin-bottom: 0.7rem;
      }

      .speakers {
        height: 1.3rem;
        width: 40%;
        margin-bottom: 0.7rem;
      }

      .live_info {
        height: 1.3rem;
        width: 80%;
      }
    }
  }
}

@include breakpoint(large) {
  .card_wrapper {
    &:hover {
      z-index: 333;

      .card {
        //transform: scale(1.1);
        //margin-top: -28.3px;
        transition: margin-top 0.3s ease-in-out, transform 0.3s ease-in-out;

        .card_footer {
          display: block;
          padding: 0.7rem;
          animation: fadeIn 0.3s ease forwards;
          animation-delay: 0.15s;
        }
      }
    }
  }
}
