#ttp-apps {
  box-shadow: $main-box-shadow;
  font-family: $h-font-family;
  position: fixed;
  height: calc(100vh - 63px);
  top: 63px;
  overflow-y: auto;
  width: 120px;
  z-index: 1;
  background-color: #fff;
  &.fetching {
    li {
      color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6.5rem;
      font-size: 2rem;
    }
  }
  & > li {
    border-bottom: 1px solid #dee2e6;
    position: relative;
    cursor: pointer;
    background-color: #fff;
  }
  .app-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 6.5rem;
    color: $portal-color;
    font-size: 1.8rem;
    padding: 1rem 0;
  }
  h4 {
    font-size: .8rem;
    font-family: $h-font-family;
    font-weight: 500;
    margin: .5rem 0 0;
  }
  .more-menu {
    position: relative;
    &:before {
      content: "";
      height: 20rem;
      left: 0;
      top: 6.55rem;
      background-color: #fff;
      position: absolute;
      width: 100%;
      display: block;
    }
    &:hover {
      color: $portal-blue;
      .app-item {
        color: $portal-blue;
      }
      .triangle {
        opacity: 1;
      }
      ul {
        transform: translateX(0);
      }
    }
  }
  .triangle {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    bottom: 0;
    border-color: transparent transparent $portal-blue;
    border-style: solid;
    border-width: 0 0 25px 25px;
  }
}
#other-apps {
  box-shadow: $main-box-shadow;
  font-family: $h-font-family;
  position: fixed;
  height: calc(100vh - 75px);
  top: 74px;
  overflow-y: auto;
  width: 250px;
  left: 120px;
  padding: .75rem 0;
  transform: translateX(-100%);
  transition: .3s transform ease-in-out;
  background-color: #fff;
  z-index: -1;
  li {
    background-color: #fff;
  }
  a {
    display: flex;
    padding: .75rem 1.5rem;
    color: $portal-color;
    font-size: 1rem;
    align-items: center;
  }
  h5 {
    font-family: $h-font-family;
    font-size: .75rem;
    margin: 0;
    font-weight: 500;
  }
  i {
    color: $icon-color;
    margin-right: .65rem;
  }
}
#all-resources {
  width: calc(100% - 120px);
  //float: right;
  margin: 62px 0 0 120px;
  transition: all .24s ease-in-out;
  padding: 2rem 2rem 0;
  position: relative;
  min-height: calc(100vh - 80px);
  background: #F7F7F7;
  .card-item {
    box-shadow: $main-box-shadow;
    border-radius: .5rem;
    border: 0;
    background-color: #fff;
    min-height: 9.5rem;
    margin-bottom: 1.5rem;
    color: $portal-color;
    overflow: hidden;
    height: auto;
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, h6, span, a {
    font-family: $h-font-family;
  }
  .article__media > img {
    width: 100%;
  }
}
#community-logo {
  background: url('/img/bg-com.jpg') no-repeat center;
  background-size: cover;
  padding: 2rem;
  img {
    max-width: 14rem;
    max-height: 7rem;
  }
}
.wheretobill {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10rem;
  i {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    color: $icon-color;
  }
  h5 {
    font-size: .9rem;
    font-weight: 500;
  }
}
#billtobox {
  i {
    font-size: 2.5rem;
  }
  h5 {
    font-size: .8rem;
  }
}
#wheretobill {
  position: relative;
  &:hover {
    #wheretobill-div {
      opacity: 1;
    }
  }
}
#wheretobill-drop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#search {
  padding: 1rem 0;
  border-top: 1px solid #eee;
  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    background-image: linear-gradient(to bottom right, $portal-color, $icon-color);
    border-radius: .25rem;
    font-family: $h-font-family;

    input {
      margin: 0;
      width: 50%;
      font-size: 1.2rem;
      font-weight: bold;
      border-radius: .25rem 0 0 .25rem;
      padding-left: 2rem;
      border: none;
      box-shadow: $shadow-like-border;
    }
    select {
      margin: 0;
      width: 15%;
      color: #FFF;
      background-color: $portal-color;
      border-radius: 0 .25rem .25rem 0;
      font-weight: 500;
      border: none;
      box-shadow: 0 0 0 0.04rem rgba(0,0,0,.5);
      padding-left: 1rem;
      font-size: .9rem;
    }
  }
}
#events-page, #events-wrapper {
  background-color: #FAFBFB;
  color: $new-main-color;
  section {
    padding: 0 4rem;
    position: relative;
    z-index: 1;
    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }
    .section-body {
      padding: 2.5rem 0;
      border-top: 1px solid $new-light-gray;
    }
    .section-footer {
      padding-top: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h2 {
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 600;
      color: $portal-new-blue;
      margin: 0;
    }
    .section-title-head {
      display: flex;
      align-items: center;
    }
    .go-app {
      font-size: .8rem;
      color: $portal-new-light-blue;
      padding: 1px 1rem 0;
      border-radius: 1rem;
      margin-left: 1rem;
      background-color: $new-title-color;
      font-weight: 600;
    }
    .section-title-subhead {
      color: $new-gray;
      font-weight: 400;
      font-size: 1rem;
      margin: 0;
    }
    .section-btn {
      display: flex;
      a, button {
        margin-left: 1rem;
        border-radius: 2rem;
        height: 2.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: .9rem;
        cursor: pointer;
      }
    }
    .btn-action {
      background: #fff;
      border: 1px solid $portal-new-light-blue;
      box-shadow: 0px 0px 10px $shadow-color;
      width: 12rem;
      font-weight: 600;
      color: $portal-new-blue;
      span {
        font-size: 1.5rem;
        margin-right: .5rem;
        font-weight: 400;
        i {
          font-size: 1rem;
        }
      }
    }
    .btn-see-more {
      width: 8rem;
      background-color: $new-very-light-gray;
      color: $new-gray;

      border: 1px solid $french-pass;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      color: $dodger-blue;
    }
  }
  .slick-dots li.slick-active button {
    background-color: $portal-new-blue;
    padding: 0px 10px;
    border-radius: 25px;
    position: relative;
    left: -5px;
  }
  .empty-avatar span {
    font-size: 1rem;
  }
}
#new-community-articles {
  li {
    &:first-child > div {
      padding-left: 0;
    }
  }
  .new-article {
    .slick-slider {
      height: 11.75rem;
    }
  }
  .slick-block {
    height: 36.5rem;
  }

}
#new-community-workflow {
  .slick-block {
    height: 34.5rem;
  }
}
#new-community-topics {
  li {
    &:first-child > div {
      padding-left: 0;
    }
    &:last-child > div {
      padding-right: 0;
    }
  }
  .slick-slider {
    height: 23.5rem;
  }
}
#new-community-medias {
  li {
    &:nth-child(4n + 1) > div {
      padding-left: 0;
    }
    &:nth-child(4n) > div {
      padding-right: 0;
    }
  }
  //background: $new-light-gray;
}
#events-list {
  // background-color: $bg-new-light-blue;
  background-color: #fafbfb;
  overflow-anchor: none; // Fix chrome(version 84.0.4147.135) scroll behavior on load more items 
  .slick-block {
    height: 24rem;
  }
}
#new-community-header {
  padding: 5.75rem 4rem 2rem;
  background: url("/img/header.jpg") no-repeat center;
  background-size: contain;
  .top-header {
    margin-bottom: 1.875rem;
  }
  .search {
    position: relative;
    color: $new-gray;
    padding-right: .5rem;
    input {
      border: 1px solid $new-light-gray;
      outline: none;
      border-radius: 2rem;
      height: 2rem;
      box-shadow: none;
      color: $new-gray;
      font-size: .875rem;
      margin: 0;
      padding-left: 3.5rem;
    }
    i {
      position: absolute;
      font-size: 1.25rem;
      top: 6px;
      left: 1.25rem;
    }
  }
  .apps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: .5rem;
    & > li {
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid $new-light-gray;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
      width: 20%;
      margin-left: 2%;
      height: 2rem;
      &:first-child {
        margin-left: 0;
      }
      & > * {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: $new-title-color;
        height: 100%;
      }
      img {
        width: 1.4375rem;
        height: 1.4375rem;
        border-radius: 50%;
      }
    }
  }
  .logo {
    align-items: center;
    display: flex;
    img {
      max-width: 13.75rem;
    }
  }
  .talk {
    padding-right: .5rem;
    iframe {
      background: url("/img/chat.png") no-repeat center;
      background-size: cover;
      width: 100%;
      height: 13.5rem;
      border-radius: 5px;
      overflow: hidden;
      border: .5rem solid #fff;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
    }
  }
  .hd-img {
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
    .slick-slider {
      height: 13.25rem;
      overflow: hidden;
      border-radius: 5px;
    }
    .slick-dots {
      display: none !important;
    }
  }
}
