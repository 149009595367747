.step_content {
  overflow-y: auto;
  height: 100%;
  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
  }
  .acceptConditions {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3c4e64;
    a {
      color: #f7953e;
    }
  }
}

.card {
  background: #f8f9fa;
  border: 1px solid #d6dbe0;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  min-height: 394px;
  display: flex;
  flex-direction: column;
}

.arrow_down {
  width: 100%;
  border-left: 12rem solid transparent;
  border-right: 12rem solid transparent;
  border-top: 20px solid #f1f2f4;
}

.card_header {
  position: relative;
  .title {
    font-weight: 500;
    text-align: center;
    font-style: normal;
    .first_part {
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      display: flex;
      margin-top: 1rem;
    }
    .second_part {
      font-size: 14px;
      line-height: 19px;
      text-transform: lowercase;
      color: #29394d;
      display: flex;
      align-items: center;
      float: right;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
  .content {
    position: relative;
    min-height: 65px;
    overflow: hidden;
    padding-top: 8px;
    background: #f1f2f4;
    .title {
      position: relative;
      z-index: 1;
    }
  }
}

.description {
  font-weight: normal;
  font-size: 14px;
  text-align: start;
  color: #29394d;
  margin: 1rem 1rem 0.4rem 1rem;
  .first_part {
    min-height: 60px;
  }
  .second_part {
    margin-top: 1rem;
    font-weight: 500;
  }
}

.actions {
  margin: 1rem;
  display: flex;
  justify-content: center;
  button {
    padding: 1.4rem 5.2rem;
    background: #f7953e;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
    &.orange {
      background: #f7953e;
    }
  }
}

.packPlaces_options {
  margin: 0.3rem 1rem;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #d8dde2;
    font-weight: 500;
    color: #29394d;
    &:hover {
      border: 1px solid #f7953e;
    }
    &.checked {
      background: #fef0e3;
      border: 1px solid #f7953e;
    }
  }
  .separator {
    align-self: stretch;
    background-color: rgb(178, 188, 198);
    width: 1px;
    box-sizing: border-box;
    margin: 0 6px;
  }
  .place_number {
    font-size: 14px;
    &__fetching {
      background: #e8eaed;
      width: 80%;
    }
  }
  .per_place_price {
    font-size: 12px;
    line-height: 2px;
    display: contents;
  }
  input[type='radio']:checked {
    .packPlaces_options.div {
      border: 1px solid #022f5d;
    }
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -2px 3px 0 -4px;
    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #f7953e;
  }
  input[type='radio'] + label span {
    background-color: #fff;
  }
  input[type='radio']:checked + label {
    color: #333;
    font-weight: 700;
  }
  input[type='radio']:checked + label span {
    background-color: #f7953e;
    border: 2px solid #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  input[type='radio'] + label span,
  input[type='radio']:checked + label span {
    -webkit-transition: background-color 0.24s linear;
    -o-transition: background-color 0.24s linear;
    -moz-transition: background-color 0.24s linear;
    transition: background-color 0.24s linear;
  }
}
