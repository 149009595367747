.feed {
  position: relative;
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 11px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #d8dde2;
  margin-left: 10px;
  padding: 0 13px;
  height: 50px;

  &:hover {
    border: 1px solid #f7953e;
  }

  &_fetching {
    background: #e8eaed;
    width: 6rem;
    margin: 11px;
  }

  &_content {
    display: flex;
    flex-direction: column;
    margin: auto 13px auto 5px;

    .not_registered{
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #6D7F92;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #29394d;
      margin: 0;
    }

    p:last-child {
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      color: #f7953e;
    }
  }

  &__expander {
    margin: auto 13px auto 0;
    cursor: move;
  }

  &__Button {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    margin-top:4%;

    span {
      height: 28px;
      width: 28px;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f1f2f4;

      &:hover {
        background: #f7953e;
        svg {
          fill: #ffffff;
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}
.addCollab {
  color: #6d7f92;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #f7953e;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #29394d;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter_btn {
    height: 32px;
  }
}
.tab {
  margin-right: 25px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  grid-column-gap: 10px;
}

:global(.Collab) {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
:global(.Collab.dragging) {
  opacity: 0.5;
  transform: scale(0.8);
  .svg {
    display: none;
  }
}

.attribution {
  &__container {
    width: 178px;
    padding: 10px;
    background: #f8f9fa;
    border: 1px solid #f7953e;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(41, 57, 77, 0.2);
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #29394d;
    margin: -0.6rem -4rem 0 0;
  }

  &__title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #29394d;
  }

  &__content {
    font-weight: normal;
    font-size: 12px;
  }
}

.pack_options {
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #d8dde2;
    padding: 4px;
  }
  div:last-child {
    border: none;
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -2px 3px 0 -4px;
    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #f7953e;
  }
  input[type='radio'] + label span {
    background-color: #fff;
  }
  input[type='radio']:checked + label {
    color: #333;
    font-weight: 700;
  }
  input[type='radio']:checked + label span {
    background-color: #f7953e;
    border: 2px solid #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  input[type='radio'] + label span,
  input[type='radio']:checked + label span {
    -webkit-transition: background-color 0.24s linear;
    -o-transition: background-color 0.24s linear;
    -moz-transition: background-color 0.24s linear;
    transition: background-color 0.24s linear;
  }
}
.customFilter {
  margin-bottom: 1.1rem;
  display: flex;
  width: 100%;
  &:hover {
    cursor: pointer;
    color: #18a0fb;
  }

  &_element {
    width: 100%;
    margin: 0 6px;
  }

  &_tab_name {
    text-align: center;
    margin-bottom: 6px;
    font-size: 14px;
  }

  &_border {
    height: 3px;
    background: #b2bcc6;
    border-radius: 50px;
  }
}
