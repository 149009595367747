#event-filters-date {
  .SingleDatePicker {
    background-color: transparent;
    width: 100%;

    .SingleDatePicker_picker {
      transform: translateY(-18px);
    }

    .SingleDatePickerInput {
      border: none;
      background-color: transparent;
      width: 100%;

      .DateInput {
        background-color: transparent;
        width: 100%;

        .DateInput_input {
          color: #fff;
          padding: 2px 8px;
          height: 32px;
          border: 0;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 500;
          background-color: transparent;
          text-transform: uppercase;

          &::placeholder {
            color: #fff;
            // text-transform: uppercase;
          }
        }

        .DateInput_fang {
          transform: translateY(-17px);
        }
      }

      .SingleDatePickerInput_clearDate {
        margin-right: 5px;
      }
      .SingleDatePickerInput_clearDate__default {
        &:focus,
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

#market-date {
  .SingleDatePicker {
    background-color: transparent;
    width: 100%;

    .SingleDatePicker_picker {
      transform: translateY(-18px);
    }

    .SingleDatePickerInput {
      border: none;
      background-color: transparent;
      width: 100%;

      .DateInput {
        background-color: transparent;
        width: 100%;

        .DateInput_input {
          color: black;
          padding: 2px 8px;
          height: 32px;
          border: 0;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 500;
          background-color: transparent;
          text-transform: uppercase;

          &::placeholder {
            color: #fff;
            // text-transform: uppercase;
          }
        }

        .DateInput_fang {
          transform: translateY(-17px);
        }
      }

      .SingleDatePickerInput_clearDate {
        margin-right: 5px;
      }
      .SingleDatePickerInput_clearDate__default {
        &:focus,
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

#ttp-date-picker-field {
  .SingleDatePicker {
    background-color: transparent;
    width: 100%;

    .SingleDatePicker_picker {
      transform: translateY(-18px);
    }

    .SingleDatePickerInput {
      border: none;
      background-color: transparent;
      width: 100%;

      .DateInput {
        background-color: transparent;
        width: 100%;

        .DateInput_input {
          color: #29394d;
          padding: 2px 8px;
          height: 32px;
          border: 0;
          font-size: 14px;
          font-family: Roboto;
          font-weight: normal;
          background-color: transparent;

          outline: none;
          box-shadow: none;

          &::placeholder {
            font-size: 12px;
            color: #6d7f92;
            font-weight: normal;
          }
        }

        .DateInput_fang {
          transform: translateY(-17px);
        }
      }

      .SingleDatePickerInput_clearDate {
        margin-right: 5px;
      }

      .SingleDatePickerInput_clearDate__default {
        &:focus,
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
