@mixin action-btn($color) {
  width: 82px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  border: 1px solid $color;
  color: $color;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  &:hover,
  &:focus {
    color: #FFFFFF;
    background: $color;
  }

  &:disabled {
    opacity: 0.7;
    cursor: unset;
  }
}

.text{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #29394D;
}

.positive_btn {
  a,
  button {
    @include action-btn(#06D9B1);
  }
}

.negative_btn {
  button {
    @include action-btn(#F9626D);
  }
}

@mixin after-action($background,$color) {
  background: $background;
  border-radius: 5px;
  padding: 9px 1rem;
  width: 11rem;
  position: relative;
  float: right;
  margin-top: 10px;
  span{
    color:$color;
    font-weight: normal;
    font-size: 12px;
  }
  a{
    text-decoration: underline;
    text-transform: lowercase;
  }
}

.success_action{
  @include after-action(#DCF5EC,#06D9B1);
}

.fail_action{
  @include after-action(#FFE6E8,#F9626D);
}


