$footerHeight: 50px;
$navBottomHeight: 64px;
$languageFilterHeight: 90px;
$offCanvasMargin: 1rem;

.modal {
  inset: unset;

  &.solid_bg {
    background-color: #29394d;
  }

  .language_filter {
    display: flex;
    justify-content: center;
    padding: 27px 0;
  }

  .search_results {
    height: calc(
      100vh - #{$footerHeight} - #{$navBottomHeight} - #{$languageFilterHeight}
    );
    padding-bottom: 2rem;
  }
}
