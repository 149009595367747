$small-height: 540px;
$medium-height: 570px;
$large-height: 700px;

@mixin small {
  @media (max-height: #{$small-height}) {
    @content;
  }
}

@mixin medium {
  @media (max-height: #{$medium-height}) {
    @content;
  }
}

@mixin large {
  @media (max-height: #{$large-height}) {
    @content;
  }
}
