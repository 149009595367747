@import 'util/util.scss';

$breakpoint-names: (small, medium, large);

$margins: (
  small: 0 -20px 0 -5px,
  medium: 0 -40px 0 -9px,
  large: -20px -80px 0 -1rem,
);

.events {
  flex: 1;
  min-height: 0;
  min-width: 0;

  .headline {
    font-style: normal;
    font-weight: bold;
    color: #ffffff !important;
    font-size: 16px;
    height: 44px;
    //margin-bottom: 1.5rem;
    margin-top: 0;
  }

  .dots_container {
    display: none;
    margin-bottom: 0 !important;
  }
}

// Media query ---------------
@each $breakpoint in $breakpoint-names {
  @include breakpoint($breakpoint) {
    .carousel_list {
      margin: map-get($margins, $breakpoint);
    }
  }
}

@include breakpoint(medium) {
  .events {
    .headline {
      height: 44px;
      font-size: 24px;
    }
  }
}

@include breakpoint(large) {
  .events {
    &:hover {
      .dots_container {
        display: flex;
        transition: display 0.3s ease-in-out;
      }
    }
  }
}
