.features {
  margin: 4rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .separator {
    position: absolute;
    width: 1px;
    height: 100px;
    background: #6D7F92;
  }

}

.container {
  display: inline-block;
  width: 100%;
}

.label {
  margin-top: 0.3rem;
  text-align: center;
  color: #FFFFFF;
  font-weight: 400;
  padding: 5px;
}
