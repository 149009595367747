@import "../styles/base/variables";

#tabs-menu {

  background: #fff;
  border-bottom: 2px solid $black;
  position: relative;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  li {
    display: flex;
    height: 3rem;
    justify-content: center;
    align-items: center;
    position: relative;
    a {
      padding: 0;
      color: $black;
      display: flex;
      height: 100%;
      align-items: center;

    }
    .active:before, .active:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      width: 0px;
      height: 0px;
      border-left: rem-calc(10px) solid transparent;
      border-right: rem-calc(10px) solid transparent;
      border-bottom: rem-calc(12px) solid $black;
      content: "";
    }
    .active:before {
      border-left: rem-calc(9px) solid transparent;
      border-right: rem-calc(9px) solid transparent;
      border-bottom: rem-calc(11px) solid #fff;
      bottom: rem-calc(-2px);
      z-index: 2;
      content: "";
    }
    cursor: pointer;
    .menu__link {
      text-transform: uppercase;
      font-weight: 500;
      padding: 0rem 1rem;
      font-size: 0.8rem;
      color: $main-color
    }
    .menu__link.active {
      color: $black;
      font-weight: 700;
      font-size: 1.2rem;
      margin-bottom: .5rem;
      transition: all .3s ease-in-out;
    }
    &:hover .menu__link {
      color: $black;
    }
  }

}
