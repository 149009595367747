.steps_content {
  overflow-y: scroll;
  padding-bottom: 70px;
  flex: 1;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
  }

  h5 {
    font-weight: normal;
    font-size: 14px;
    color: #29394d;
  }
}
