.exhibitor {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  min-height: 34px;

  .title {
    font-weight: normal;
    font-size: 12px;
    color: #3c4e64;
  }
}

.logoWrapper {
  margin-right: 1rem;
  width: 68px;
  height: 34px;
  background: #fff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.5rem;

  span {
    background-color: #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
}

.session {
  position: relative;
  overflow: hidden;
  background: #f8f9fa;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
  min-height: 356px;
  transition: 0.3s box-shadow ease;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  }

  &.hilighted {
    background: #edfaf5;
  }

  .sessionHeader {
    margin-bottom: 1rem;

    .block {
      display: flex;
      align-items: center;
      color: #29394d;

      span {
        font-weight: normal;
        font-size: 12px;

        &.sessionTime {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .separator {
      font-size: 11px;
      margin: 0 1rem;
      color: #b2bcc6;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
    margin-bottom: 0.2rem;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    color: #6d7f92;
  }

  .pictureBox {
    position: relative;
    margin-top: 2rem;

    .label {
      margin-left: 1rem;
      position: absolute;
      top: 0;
      left: 0;

      width: 63.57px;
      height: 19px;

      background: #ffffff;
      border-radius: 50px;
      transform: translateY(-50%);

      text-align: center;
      font-weight: normal;
      font-size: 10px;
      color: #3c4e64;
    }

    .logo {
      height: 182px;
      border-radius: 5px;
      background: linear-gradient(
        180deg,
        rgba(41, 57, 77, 0) 0%,
        rgba(41, 57, 77, 0.279) 48.96%,
        rgba(41, 57, 77, 0.81) 100%
      );
      background-size: cover;
      background-repeat: no-repeat;

      .extrasWrapper {
        padding: 0.5rem;
      }
    }
  }

  .actionWrapper {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
    margin-right: 1rem;
  }

  .actions {
    display: flex;
    align-items: center;
    height: 34px;

    & > * {
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .joinButton {
      padding: 0 1rem;

      font-weight: 500;
      font-size: 14px;
      color: #ffffff;

      background: #18a0fb;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;

      &.compact {
        width: 34px;
        padding: 0;
      }
    }

    .addButton {
      width: 34px;
      margin-left: 0.5rem;

      background: #06d9b1;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
    }
  }

  &.secondary {
    min-height: 194px;
    background: linear-gradient(
      180deg,
      rgba(41, 57, 77, 0) 0%,
      rgba(41, 57, 77, 0.279) 48.96%,
      rgba(41, 57, 77, 0.81) 100%
    );
    background-size: cover;
    background-repeat: no-repeat;

    .extrasWrapper {
      margin-bottom: 0.5rem;
    }

    h3,
    h6 {
      color: #ffffff;
    }

    .sessionHeader {
      margin-bottom: 0;
      .block span {
        color: #ffffff;
      }
    }

    .secondaryHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.compacted {
    min-height: 105px;

    h3 {
      font-weight: 500;
      font-size: 14px;
      margin-right: 0.5rem;
    }

    .secondaryHeader {
      align-items: flex-start;
    }

    .logoWrapper {
      width: 57.39px;
      height: 40px;
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }

  .spacer {
    flex: 1;
  }

  .soldOut {
    position: absolute;
    background: #f7953e;
    transform: rotate(45deg);
    height: 18px;
    width: 100px;
    right: -22px;
    top: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .slot_mode {
    display: flex;
    align-items: center;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #ffffff;
    }
  }
}

.fetching {
  box-shadow: none;

  h3,
  h6 {
    height: 1rem;
    background: linear-gradient(to right, #f9f9f9, #eee, #e5e5e5, #dedede);
    border-radius: 2px;
  }

  h6 {
    width: 7rem;
  }
}

.liveIcon {
  width: 18px;
  height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #ff636e 0%,
    rgba(255, 99, 110, 0) 100%
  );
  border-radius: 50px;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fe3745;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  }
}

.layout3 {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.3s box-shadow ease;
  display: flex;
  justify-content: space-between;

  .sessionHeader {
    margin-bottom: 0.2rem;

    .block {
      display: flex;
      align-items: center;
      color: #29394d;

      span {
        font-weight: normal;
        font-size: 10px;

        &.sessionTime {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .separator {
      font-size: 11px;
      margin: 0 0.5rem;
      color: #b2bcc6;
    }
  }

  &:hover {
    box-shadow: 0px 1px 6px rgba(41, 57, 77, 0.1);
  }

  &.hilighted {
    background: #edfaf5;
    border: 1px solid #dcf5ec;
  }

  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #29394d;
  }

  .soldOut {
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: #f7953e;
  }
}

.extras {
  background: #fff;
  padding: 0.2rem 1rem;
  display: flex;
  border-radius: 20px;
  width: 52px;
  height: 22px;
  justify-content: center;

  .extrasItem {
    display: flex;
    font-size: 12px;
    align-items: center;

    span {
      margin-left: 0.5rem;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.layout4 {
  overflow: hidden;
  background: #f8f9fa;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s box-shadow ease;
  position: relative;

  .soldOut {
    position: absolute;
    background: #f7953e;
    transform: rotate(45deg);
    height: 18px;
    width: 100px;
    right: -22px;
    top: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .sessionHeader {
    .block {
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        line-height: 13px;
        color: #29394d;
        font-weight: normal;

        &.sessionTime {
          color: #3c4e64;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .time {
        font-weight: 500;
        font-size: 14px;
        color: #29394d;
      }
    }

    .separator {
      margin: 0 0.5rem;
      color: #b2bcc6;
    }
  }

  &:hover {
    box-shadow: 0px 1px 6px rgba(41, 57, 77, 0.1);
  }

  &.hilighted {
    background: #edfaf5;
    border: 1px solid #dcf5ec;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
    margin: 0;
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    color: #6d7f92;
  }

  .logo {
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(41, 57, 77, 0) 0%,
      rgba(41, 57, 77, 0.279) 48.96%,
      rgba(41, 57, 77, 0.81) 100%
    );
    background-size: cover;
    background-repeat: no-repeat;
    height: 100px;
    width: 220px;

    .extrasWrapper {
      padding: 0.5rem;
    }
  }
}
