#offCanvas {
  background-color: $main-blue;
  color: #fff;
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.06);
  font-size: 1.2rem;
  &:after {
    box-shadow: none;
  }
  .close {
    right: 15%;
    top: 1rem;
    &:before, &:after {
      border-color: #fff;
    }
  }
  ul {
    padding: 3rem 0 2rem 2rem;
    li {
      padding: 0.5rem 0;
      font-weight: 500;
      display: flex;
      align-items: center;
      a {
        color: #fff;
        transition: nth($transitions, 3);
        &:hover {
          font-weight: bold;
        }
      }
      span {
        font-weight: bold;
        color: #fff;
        padding: 0.7rem 1rem;
      }
    }
  }
  &.is-open + .off-canvas-content {
    transform: translate(8.9%, -7%) scale(0.83);
  }
}
