.wrapper {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px 5px;

  iframe {
    width: 100%;
    height: 90%;
    box-shadow: none;
    border: none;
  }
}
