.slick_dots {
  bottom: -30px;
}
.leftAlignSlick > :global(.slick-list) > :global(.slick-track) {
  margin-left: 0;
}

.navButtons {
  display: flex;
}

@media (max-width: 1024px) {
  .navButtons {
    display: none;
  }
}


.arrow_button {
  height: 44px;
  width: 44px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8dde2;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  &:hover {
    box-shadow: 0px 2px 5px rgba(41, 57, 77, 0.1);
    svg {
      fill: #6d7f92;
    }
  }
}

.mainAction {
  height: 32px;
  padding: 0 1rem;
  background: #ffffff;
  border: 1px solid #d8dde2;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  font-size: 12px;
  color: #6d7f92;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #f1f2f4;
    color: #6d7f92;
  }
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 10px;
    height: 10px;
    background: #b2bcc6;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s all ease;

    &.active {
      background: #18a0fb;
      width: 20px;
      border-radius: 10px;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &:hover {
      border: 1px solid #2495e1;
    }
  }

  &.orange {
    span {
      &.active {
        background: #f47f4d;
      }
      &:hover {
        border: 1px solid #f47f4d;
      }
    }
  }

  &.purple {
    span {
      &.active {
        background: #6f6cec;
      }
      &:hover {
        border: 1px solid #7270eb;
      }
    }
  }

  &.redOrange {
    span {
      &.active {
        background: #cc1c4d;
      }
      &:hover {
        border: 1px solid #cc1c4d;
      }
    }
  }
}

@media (max-width: 420px) {
  .dots {
    display: none;
  }
  .title {
    margin-top: 15px;
  }
}

@media (max-width: 830px) {
  .title {
    margin-top: 15px;
  }

  .dots {
    margin-top: 15px;
  }
}


.dots_padding {
  padding: 0rem 1rem;
  padding-bottom: 0.5rem;
}

.separator {
  margin: 1rem 0;
  margin-right: 1rem;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #29394d;
}
