.adModal {
  justify-content: center;
  align-items: center;
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #FFF5DE;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 27px;
      width: 27px;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394D;
  }
  h4 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #29394D;
  }
  .join {
    background: #18A0FB;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding: 10px 20px;
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
    }
  }

  .DISCOUNT_AD {
    background: #ffeff0;
    color: #fc4275;
    svg {
      fill: #fc4275;
    }  
  }

  .GENERIC_AD {
    background: rgba(108, 84, 255, 0.1);
    color: #6c54ff;
    svg {
      fill: #6c54ff;
    }  
  }
}

