$blueColor: #18a0fb;
$redOrangeColor: #fb1e5b;
$orangeColor: #f47f4d;
$purpleColor: #6f6cec;

.wrapper {
  display: flex;
  text-transform: none;

  .borderline {
    width: 3px;
    border-radius: 5px;
    margin-right: 1rem;
    flex: 0 0 auto;

    &.blue {
      background-color: $blueColor;
    }
    &.redOrange {
      background-color: $redOrangeColor;
    }
    &.orange {
      background-color: $orangeColor;
    }
    &.purple {
      background-color: $purpleColor;
    }
  }

  .preview {
    width: 100%;

    &_header {
      .banner {
        height: 54px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(41, 57, 77, 0.1);

        &.empty_banner {
          background-size: contain;
        }
      }

      .titles {
        padding: 0 1rem;

        h1 {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #29394d;
        }

        h2 {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6d7f92;
        }

        h3 {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;

          &.blue {
            color: $blueColor;
          }
          &.redOrange {
            color: $redOrangeColor;
          }
          &.orange {
            color: $orangeColor;
          }
          &.purple {
            color: $purpleColor;
          }
        }
      }

      .delete_icon {
        display: flex;
        align-items: center;
        width: 28px;
        height: 28px;
        padding: 0.4rem;
        background-color: #f1f2f4;
        border-radius: 50%;
        cursor: pointer;

        &:hover:not(.disabled) {
          background-color: #d7dbe0;
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }

    &_description {
      display: flex;
      justify-content: space-between;

      ul {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: #29394d;
        padding-right: 1rem;

        li {
          margin-bottom: 5px;
        }
      }
    }

    &_footer {
      margin-top: 0.8rem;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        background-color: #06d9b1;
        color: #fff !important;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        padding: 0.6rem 1.2rem !important;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

        &:hover {
          background-color: #02af8e;
        }

        &.disabled {
          background-color: #02af8e;
          pointer-events: none;
        }
      }
    }
  }
}
