$main-color: var(--primary-color);

.collaborators_step{
  padding-top: 2%;
  font-family: Roboto;
  color: #29394D;
  .text_title{
    margin-left: 1.5%;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }

  .description_text{
    padding: 0.5% 1.5% 0 1.5%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6D7F92;

    a{
      color: #6D7F92;
      text-decoration-line: underline;
    }
  }

  .add_collaborators{
    padding: 0.7% 0.7%;
    overflow-y: auto;
    //margin-top: 1%;
    position: absolute;
    width: 414px;
    height: 65%;
    background: #FFFFFF;
    border: 1px solid #B2BCC6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;

    .cumulate_filter {
      padding-bottom: 10px;
      margin-top: 3%;

      .cumulate_filter_div {
        width: 182px;
        height: 3px;
        background: #b2bcc6;
        border-radius: 50px;
      }

      &:hover {
        cursor: pointer;
        color: #18a0fb;
      }
    }

    .bloc_title{
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      &:hover {
        cursor: pointer;
      }

    }
    .choice{
      margin-top: 1%;
      position: absolute;
      width: 182px;
      height: 3px;
      border-radius: 50px;
      transform: matrix(-1, 0, 0, -1, 0, 0);

      &:hover {
        cursor: pointer;
      }

    }

    :global(.ant-tabs-nav){
      padding-left: 13%;
    }

    :global(.ant-tabs-tab):hover{
      color: #F7953E;
    }

    :global(.ant-tabs-ink-bar){
      background: #F7953E;
    }

    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
      color: #F7953E;
    }

  }

  .disabledDiv {
    pointer-events: none;
    opacity: 0.4;
  }

  .collaborator_form{
    //overflow-y: scroll;
    //height: 310px;
    margin-left: 2%;

    .weight_400 {
      font-weight: 350;
    }

    .border_red{

      input{
        border : 1px solid #FE3745;
      }
    }

    .error_bloc{
      display: inline-flex;
      width: 100%;

      .error_message{
        margin-left: 1%;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #FE3745;
      }
    }


    .input_text{
      position: absolute;
      width: 182px;
      height: 34px;

      background: #F8F9FA;
      border: 1px solid #B2BCC6;
      box-sizing: border-box;
      border-radius: 4px;

      &:focus {
        border: 1px solid #F7953E;
      }
    }

    .select_input{
      width: 375px;
      height: 34px;
    }
    .actions_block {
      margin-left: -2%;
      margin-top: 4%;
      position: absolute;
      width: 411px;
      height: 75px;
      background: #FFFFFF;
      //box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;

      .submit_btn {
        margin-top: 3%;
        color: #FFFFFF;
        position: absolute;
        width: 114px;
        height: 38px;
        background: #F7953E;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        text-transform: uppercase;
        margin-left: 15%;

        cursor: pointer;

      }

      .cancel_btn {
        margin-top: 4%;
        margin-left: 2%;
        color: #6D7F92;
        position: absolute;
        width: 114px;
        height: 38px;
        background: #F1F2F4;
        border-radius: 4px;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .upload_zone{
    width: 95.5%;
    margin-top: 3%;
    margin-left: 2%;

    .upload_result{
      margin-bottom: 2%;
      width: 376px;
      height: 79px;
      background: #FFFFFF;
      border: 1px solid #F7953E;
      box-sizing: border-box;
      border-radius: 4px;

      .upload_result_content{
        padding-left: 3%;
        padding-Top: 3%;

        .file_name{
          font-weight: 500;
          margin-left: 2%;
          margin-right: 2%;
        }
        .numbers{
          font-weight: normal;
          margin-top: 3%;

          span:nth-child(1),
          span:nth-child(2){
            padding-right: 1%;
            padding-left: 1%;
            border-right: 1px solid #D8DDE2;
          }
          span:nth-child(3){
            padding-left: 1%;
          }
        }
      }
    }
    .dropzone {
      background: #f8f9fa;
      border: 1px dashed #b2bcc6;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 0 1.3rem;
      color: #6d7f92;
      transition: all .2s ease-in-out;
      cursor: pointer;
      &:hover {
        border-color: #F7953E;
        background: #f3faff;
      }
      p {
        margin: 0;
        font-weight: normal;
      }

      i {
        font-size: 2.25rem;
        margin-bottom: 0.75rem;
      }
      .size {
        font-weight: 500;
      }
    }

    .btn_download{

        width: 180px;
        height: 28px;
        background: #F1F2F4;
        border: 0.5px solid #B2BCC6;
        box-sizing: border-box;
        border-radius: 50px;
        color: #6D7F92;
        font-weight: normal;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 21px 110px 13px;
        padding: 0 1rem;
        cursor: pointer;
        transition: all .2s ease-in-out;
        &:hover {
          color: #3c4e64;
          svg {
            fill: #3c4e64;
          }
        }
    }
  }

  .gradiant {
    background-color: rgb(238, 238, 238);
    background-image: linear-gradient(
                    90deg,
                    rgb(238, 238, 238),
                    rgb(245, 245, 245),
                    rgb(238, 238, 238)
    );
    background-repeat: no-repeat;
    animation: 1.2s ease-in-out 0s infinite normal none running;
    animation-name: loading;
  }

  .fetching_table {
    margin-top: 3%;
    padding-left: 6%;

    .collab_count {
      width: 150px;
      height: 15px;
      border-radius: 5px;
    }

    .table {
      margin-right: 5%;
      padding-bottom: 7px;

      &_header {
        display: flex;
        align-items: center;
        margin: 15px 0px 0px;

        .table_frame {
          width: 908px;
          height: 400px;
          border-radius: 5px;
        }
      }
    }
  }

  .collaborator_list{
    width: auto;
    height: 93%;
    overflow-y: auto;
    max-height: 800px;
    /* table */
    :global(.ant-table) {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #29394D;
    }

    /* row data */
    :global(.ant-table-tbody) > tr > td {
      //height: 10px;
      padding: 8px;
      background: #FAFBFB;
    }
    /* row tags */
    .my-tag {
      font-size: 12px;
    }
    :global(.ant-table-thead) > tr > th {
      //height: 5px;
      //padding: 4px;
      color: #29394D;
      background: #FFFFFF;
    }

    :global(.ant-table table) { border-spacing: 0 5px; }

    :global(.ant-table-body){
      margin-top: -3%;
    }
    .actions_btn{
      //margin-left: 40%;

      &:hover {
        cursor: pointer;
      }

      .ant-menu-item-selected {
        color: #ff0000 !important;
      }
      .delete_icon{
       color : #ff0000;
        margin-left: 1%;
      }
    }


  }


  .search_input {
    margin-left: -13%;
    background: #FFF;
    display: flex;
    position: relative;
    z-index: 1;
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 30px;
    width: 300px;
    height: 32px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px;

      &.clear_input {
        cursor: pointer;
        visibility: hidden;
        transition: all .3s ease;

        &.visible {
          visibility: visible;
        }
      }
    }

    input {
      flex: 1;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

.delete_form{
  //padding: 2%;
  padding-bottom: 1%;
  padding-top: 3%;
  padding-left: 2%;

  input[type='radio']:after {
    width: 22px;
    height: 22px;
    top: -4px;
    left: -1px;
    position: relative;
    background-color: #FFFFFF;
    content: '';
    display: inline-block;
    visibility: visible;

    border: 2px solid #B2BCC6;
    box-sizing: border-box;
    border-radius: 50px;
  }

  input[type='radio']:checked:after {
    width: 22px;
    height: 22px;
    top: -4px;
    left: -1px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    background: #FF636E;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 50px;
  }

  .error_bloc{
    display: inline-flex;
    width: 100%;
    margin-left: 6%;
    margin-top: 10%;
    margin-bottom: -4%;
    .error_message{
      margin-left: 1%;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #FE3745;
    }
  }

  .modal_title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #29394D;
  }
  .field {
    display: block;
    margin: 1rem auto;
    flex: unset;
    position: absolute;
    width: 22px;
    height: 22px;
    background: #FFFFFF;
    border: 2px solid #B2BCC6;
    box-sizing: border-box;
    border-radius: 50px;
  }
  .option_wrapper{
    display: block;
  }

  .input_email{
    position: absolute;
    width: 400px;
    height: 40px;
    margin-top: 2%;
    margin-left: 6%;
    background: #FFFFFF;
    border: 1px solid #FF636E;
    box-sizing: border-box;
    border-radius: 4px;

    ::placeholder {

      position: absolute;
      width: 208px;
      height: 15px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;

      color: #6D7F92;
    }

    :focus{
      border: 1px solid #FF636E;
    }
  }

  .input_email_disabled{
    position: absolute;
    width: 400px;
    height: 40px;
    margin-top: 2%;
    margin-left: 6%;
    background: #F8F9FA;
    border: 1px solid #B2BCC6;
    box-sizing: border-box;
    border-radius: 4px;

    ::placeholder {

      position: absolute;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;

      color: #6D7F92;
    }
  }

  .weight_200 {
    font-weight: 200;
  }

  .delete_btn{
    color: #FFFFFF;
    position: absolute;
    width: 204px;
    height: 44px;
    background: #FF636E;
    border-radius: 5px;
    text-transform: uppercase;
    margin-left: 11%;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

   }

   .cancel_btn{
     font-family: Roboto;
     font-style: normal;
     font-weight: 500;
     font-size: 16px;
     line-height: 19px;
     /* identical to box height */

     text-transform: uppercase;

     color: #6D7F92;
     position: absolute;
     width: 126px;
     height: 44px;
     background: #F1F2F4;
     border: 1px solid #6D7F92;
     box-sizing: border-box;
     border-radius: 5px;

    :hover{
      cursor: pointer;
    }
  }
}