$blue-color: #18a0fb;
$blue-light-2-color: '#c7e8fe';

$orange-color: #f47f4d;
$orange-light-2-color: #ffe1cc;

$redOrange-color: #fb1e5b;
$redOrange-light-4-color: #ffabc2;

$purple-color: #6F6CEC;
$purple-light-2-color: #D7CFFF;

$main-color: var(--primary-color);
$secondary-color: var(--secondary-color);

$green-teal-color: #14B7AD;
$green-teal-light-color: #6FD3C9;

.switcher {
  --primary-color: #{$blue-color};
  --secondary-color: #{$blue-light-2-color};

  &.orange {
    --primary-color: #{$orange-color};
    --secondary-color: #{$orange-light-2-color};
  }
  &.redOrange {
    --primary-color: #{$redOrange-color};
    --secondary-color: #{$redOrange-light-4-color};
  }
  &.purple {
    --primary-color: #{$purple-color};
    --secondary-color: #{$purple-light-2-color};
  }
  &.greenTeal {
    --primary-color: #{$green-teal-color};
    --secondary-color: #{$green-teal-light-color};
  }

  cursor: pointer;
  display: flex;

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  input {
    display: none;
    opacity: 0;

    & + span {
      &:not(.checkMark) {
        margin-right: 0.3rem;
      }
    }

    &:checked {
      & + span {
        color: $main-color;
      }

      & + .checkMark,
      & + * + .checkMark {
        background: $secondary-color;

        &::after {
          background: $main-color;
          box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
          left: unset;
          right: 0;
        }
      }
    }
  }

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
  }

  .checkMark {
    position: relative;
    width: 40px;
    height: 16px;
    background: #dbe0e5;
    border-radius: 20px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: -4px;
      width: 24px;
      height: 24px;
      background: #b6bfc8;
      border: 1px solid #ffffff;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      transition: all 0.5s ease;
    }
  }
}
