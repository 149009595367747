@import 'util/util.scss';

$breakpoint-names: (small, medium, large);

$padding: (
  small: 10px 20px,
  medium: 20px 40px,
  large: 30px 80px,
);

@each $breakpoint in $breakpoint-names {
  @include breakpoint($breakpoint) {
    .wrapper {
      padding: map-get($padding, $breakpoint);
    }
  }
}
