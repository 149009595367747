.calendar_wrapper {
  position: relative;
  width: fit-content;

  .calendar_btn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    //width: 158px;
    height: 32px;
    padding: 0 0.3rem;
    background: #f1f2f4;
    border-radius: 5px;
    cursor: pointer;

    &>* {
      margin: 0.5rem;
    }

    .calendar_label {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
    }
  }

  .calendar_options {
    position: absolute;
    top: 110%;
    background-color: #fff;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #f1f2f4;
    z-index: 50;

    ul {
      li {
        width: 100%;
        margin-bottom: 0 !important;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;

        .grey_icon * {
          fill: #6d7f92;
        }

        a {
          display: flex;
          align-items: center;
          color: #6d7f92;
          width: 100%;
          padding: 10px;
        }

        &:hover {
          background: #f8f9fa;
        }
      }
    }
  }
}

.watch {
  .calendar_btn {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 13px;

    &>* {
      margin: 0;
    }

    &:hover {
      background-color: #6d7f92;
      border: 1px solid #6d7f92;
    }
  }

  .calendar_options {
    z-index: auto;
    background-color: #29394d;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    width: max-content;
    z-index: 200;

    ul {
      li {
        font-size: 14px;

        a {
          color: #fff !important;
          border-radius: 5px;
          // height: 42px;
          padding-left: 28px;
          padding-right: 28px;
        }

        &:hover {
          background-color: #6d7f92;
        }
      }
    }
  }
}