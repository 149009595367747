@import 'styles/base/colors';

$colors: (
  blue: (
    default: $blue,
    text: $white,
  ),
  coralRed: (
    default: $coralRed,
    text: $white,
  ),
  redOrange: (
    default: $redOrange,
    text: $white,
  ),
  greenTeal: (
    default: $greenTeal,
    text: $white,
  ),
  slateGrey: (
    default: #3c4e64,
    text: $white,
  ),
  white: (
    default: $white,
    text: $slateGrey,
  ),
  orange: (
    default: $orange,
    text: $white,
  ),
);

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 2px 8px;

  @each $color, $variants in $colors {
    &.#{$color} {
      background-color: map-get($variants, 'default');
      color: map-get($variants, 'text');

      &.transparent {
        background-color: rgba(map-get($variants, 'default'), 0.7);
      }
    }
  }
}
