.yup-error {
  p {
    font-size: 12px;
    margin: 0.2rem 0.5rem;
    font-weight: normal;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #fe3745;
  }
}
