.goBack {
  padding: 0.9rem 1rem;
  background: #ffffff;
  // border: 1px solid #b2bcc6;
  border: 1px solid #d7dbe0;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #6d7f92;
    // margin-left: 0.5rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover {
    border-color: #b2bcc6;
  }

  &:not(.disabled):hover {
    background: #f1f2f4;
  }
}
