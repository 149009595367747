html,
body,
#app {
  height: 100%;
}

body {
  color: $main-color;
  font-weight: 500;
  font-size: 14px;
  background: #fff;
  backface-visibility: hidden;

  button {
    outline: 0;
  }
  ul,
  .button {
    margin: 0;
  }
  .nth1 {
    transition: nth($transitions, 1);
  }
  .nth3 {
    transition: nth($transitions, 3);
  }
  /*.close {
    position: absolute;
    top: 1.2rem;
    right: 10%;
    cursor: pointer;
    &:before, &:after {
      content: ' ';
      border-top: 0.1rem solid #B9B9B9;
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;

    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
      left: 0.925rem;
    }
    &:hover {
      &:before, &:after {
        border-color: $gray-dark;
      }
    }
  }*/
  .close {
    cursor: pointer;
    position: relative;
    height: 1.2rem;
    width: 1.25rem;
    &::before,
    &::after {
      content: '';
      width: 2px;
      height: 1.3rem;
      background: #444;
      display: block;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 30%;
    }
    &::before {
      top: 0;
      left: calc(50% - 1px);
    }
    &::after {
      height: 2px;
      width: 1.3rem;
      left: 0;
      top: calc(50% - 1px);
    }

    &.dark {
      &::before,
      &::after {
        background: #fff;
      }
    }
  }
  .loading-bar {
    background-color: $main-blue;
    background: linear-gradient(to right, #30609b, $main-blue, #7cb5f9);
    box-shadow: 0 0 10px rgba(119, 182, 255, 0.7);
    height: 0.2rem;
    position: fixed;
    z-index: 2;
  }
  .empty-avatar {
    background: $bg-empty-avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1),
      0 0 0 1px rgba(44, 43, 63, 0.13);
    span {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .article__title a {
    color: #3f3f3f;
    &:hover {
      color: $a-hover-color;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $mask-bg-color;
    z-index: 1;
  }
  .sidebar {
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 75%;
    right: 0;
    z-index: 2;
    transform: translateX(100%);
    transition: nth($transitions, 5);
    &.display {
      transform: translateX(0);
      box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
    }
  }
  .toastify__body {
    color: #eee;
  }
}
#main {
  color: $main-color;
  min-height: 50rem;
  background-color: #fff;

  .hide,
  .hidePeerPage {
    display: none;
  }
  .see-more {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.8rem;
    cursor: pointer;
    font-size: 0.8rem;
    color: #aaa;
    transition: 0.3s all ease-in-out;
    text-transform: lowercase;
    border: none;
    &:hover {
      color: #f49649;
      font-weight: 600;
    }
    .icon {
      font-size: 0.6rem;
      position: relative;
      top: 0.5rem;
      left: -0.2rem;
    }
  }
  .hidedItem {
    display: none;
    transition: none;
  }
  .zoom__activated {
    & > .column {
      transition: nth($transitions, 2);
      &:last-child {
        transform: scale(1.2);
      }
      .tmlp__part {
        transform: scale(1.2);
      }
    }
  }
  .centered-img {
    overflow: hidden;
    text-align: center;
    position: relative;

    &::before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .dotted:before {
    content: '';
    border-radius: 2rem;
    background-color: $main-orange;
    top: 22%;
    right: 0;
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    border: 0.15rem solid #fff;
  }
}

// General controls
#controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem 20rem;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  transform: translateY(100%);
  transition: nth($transitions, 4);
  &.display {
    transform: translateY(0);
  }
}

.controls__cancel,
.controls__ok {
  padding: 1rem 2.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  transition: nth($transitions, 3);
  cursor: pointer;
  border-radius: 2rem;
  outline: 0;
}
.controls__cancel {
  border: none;
  box-shadow: $shadow-like-border;
  color: $main-color;
  &:hover {
    color: $h-color;
    box-shadow: $shadow-like-border;
  }
}
.controls__ok {
  display: flex;
  align-items: center;
  box-shadow: 0 0.06rem 0.28rem 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  background-image: linear-gradient(104deg, #57d0f2 0%, $main-blue 100%);
  font-weight: 600;
  background-size: 100%;
  &.with-icon {
    font-weight: 400;
  }
  &:hover {
    background-image: linear-gradient(104deg, #6facf7 0%, $main-blue 100%);
  }
  .icon {
    position: relative;
    left: 1.6rem;
    background: #fff;
    border-radius: 50%;
    padding: 0.4rem;
    color: $h-color;
    box-shadow: 0 0.06rem 0.28rem 0 rgba(0, 0, 0, 0.1);
  }
}
.btn_small {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
}
#loader {
  .svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
#auto-login {
  min-height: 50rem;
  background-color: #fff;
}
.scale1_2 {
  transform: scale(1.2);
}
@include mui-series {
  //#right-side{ @include mui-queue(0.5s, 0s, slide($direction: left)); animation-fill-mode: backwards;}
  //#masonryBlock { @include mui-queue(1s, 0s, fade, in); animation-fill-mode: backwards;}
  //#pagesMenu { @include mui-queue(0.5s, 0s, fade, in); animation-fill-mode: backwards;}
  //#mainMenu { @include mui-queue(0.5s, 0s, slide($direction: down)); animation-fill-mode: backwards;}

  //#mask-left-side { @include mui-queue(0.5s, 0s, fade, hinge); animation-fill-mode: backwards;}
  //.chooseTmplLabel { @include mui-queue(0.5s, 0s, slide($direction: right)); animation-fill-mode: backwards;}
  //#dock { @include mui-queue(0.5s, 0s, fade, zoom); animation-fill-mode: backwards;}
  //#chooseTmpl { @include mui-queue(0.5s, 0s, fade, zoom); animation-fill-mode: backwards;}
  //#sidebar{ @include mui-queue(1s, 0s, slide($direction: left)); animation-fill-mode: backwards;}
}

.page {
  padding: 2rem 0;
}

/**
 * Correctif de style @TODO to be replaceed by something more consistent
 */
.react-autosuggest__container {
  height: 100%;
  input {
    height: 100%;
  }
}
.react-autosuggest__suggestions-container {
  top: 120%;
  padding: 0;
}
.react-autosuggest__suggestions-container--open {
  padding: 0 !important;
}
.react-autosuggest__suggestions-container--open
  .react-autosuggest__suggestions-list
  .react-autosuggest__suggestion:hover,
.react-autosuggest__suggestions-container--open
  .react-autosuggest__suggestions-list
  .react-autosuggest__suggestion--highlighted {
  background-color: #deebfe !important;
}
.react-autosuggest__suggestion {
  padding: 8px 0 8px 12px !important;
}
body.ReactModal__Body--open {
  overflow: hidden;
}

@include breakpoint(xxlarge only) {
  .grid-container {
    max-width: 80rem;
  }
}

/**
 * ***************************************************************************
 */

.ripple_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  &.fixed-loader {
    position: fixed;
    top: 18rem;
  }
}

// ADD Margin Left when sub-menu open on Left
.off-canvas-wrapper.marginAppLeft {
  & > :not(#headerSpaceMaintainer):not(#AdvancedLayoutPage) {
    margin-left: 3.5rem;
  }
}

// Fix vertical subMenu size
.off-canvas-wrapper {
  min-height: 100vh;
}

.tiny-loader {
  width: 40px;
}

.greetings {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// .ReactModal__Overlay {
//   z-index: 10000 !important;
// }
