.wrapper {
  overflow-y: scroll;
  padding-bottom: 70px;
  flex: 1;
}

.content {
  padding: 1rem;

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
  }
}

.invoicing {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .invoicingTitle {
    font-size: 14px !important;
    line-height: 16px !important;
    color: #18a0fb !important;
  }
}

.acceptConditions {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3c4e64;
}

.inputLabel {
  font-weight: normal;
}
