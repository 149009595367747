.speakersNavigation {
  z-index: 2;

  .slider {
    display: flex;
  }

  .avatar {
    margin-right: 2.7rem !important;
    cursor: pointer;
  }

  .avatarName:hover {
    color: #18a0fb !important;
  }

  .sliderPrev {
    transform: rotate(180deg);
  }

  :global(.slick-slider) > :global(.slick-list) > :global(.slick-track) {
    margin-left: 0;
    display: flex;
  }

  :global(.slick-disabled) {
    display: none;
  }

  :global(.slick-prev) {
    top: 11%;
    padding-left: 50px;
  }

  :global(.slick-initialized .slick-slide) {
    width: max-content !important;
    display: flex;
  }
  :global(.slick-prev:before) {
    content: '' !important;
  }
  :global(.slick-next) {
    padding-left: 20%;
    height: 72px;
    width: 80px;
    top: 23%;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(250, 251, 251, 0.9) 64.58%,
      rgba(250, 251, 251, 0) 100%
    ) !important;

    &.active {
      background: linear-gradient(
        270deg,
        #edfaf5 0%,
        rgba(240, 251, 251, 90%) 46.58%,
        rgba(189, 247, 247, 0%) 100%
      ) !important;
    }
  }
  :global(.slick-next:before) {
    content: '' !important;
  }
}

.hideShadow {
  box-shadow: none !important;
}
