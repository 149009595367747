$btn-info-color: #18A0FB;
$btn-success-color: #02AF8E;
$btn-success-border-color: #06D9B1;

.ttp-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    background: transparent;
    padding: 0.8rem 1rem;
    cursor: pointer;
    outline: none;
    &.info {
        color: $btn-info-color;
        border: 0.5px solid $btn-info-color;
    }
    &.success {
        color: $btn-success-color;
        border: 0.5px solid $btn-success-border-color;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        padding: 0.8rem 5rem;
    }
}
.blue {
  color: $dodger-blue;

  &:hover {
    background: linear-gradient(180deg, #18A0FB 0%, #2495E1 100%);
    color: #fff;
  }
}

.btn {
    min-width: 180px;
    padding: 0 1rem;
    height: 44px;
    border: 1px solid rgba(199, 232, 254, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    border-radius: 30px;
    font-family: Roboto;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    margin-left: 22px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      box-shadow: none;
    }

    img, svg {
      margin-right: 12px;
    }

    &.secondary {
      background: #FFFFFF;
      letter-spacing: 0.06em;
      color: #18A0FB;
    }

    &.primary {
      background: #06D9B1;
      letter-spacing: 0.04em;
      color: #FFFFFF;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
