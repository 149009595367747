#viewer, #articleViewer {
  width: 100%;
  .rmodal__body {
    padding: 0;
  }
  .rmodal__close {
    right: 1rem;
    top: -1rem;
  }
  .empty-avatar{
    top: 0;
    margin: 0 1rem 0 0;
  }
  .social-media{
    background-color: $portal-color;
  }
}
#articleViewer {
  padding-top: 4rem;
  .rmodal__article-header {
    position: fixed;
    height: 4rem;
    box-shadow: $shadow-like-border;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    align-items: center;
    padding: 0 7rem;
    input {
      margin: 0;
      font-family: $h-font-family;
      border: none;
      background-color: #F5F5F5;
      box-shadow: none;
      border-radius: 2rem;
      padding: .5rem 3rem .5rem 2rem;
      font-size: .8rem;
      font-weight: 500;
    }
    a {
      position: absolute;
      right: 7.3rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $icon-color;
      border-radius: 50%;
      font-size: 1rem;
      color: #fff;
      transform: rotate(45deg);
    }
  }
  .rmodal__close {
    z-index: 2;
    background: #fff;
    top: 0.5rem;
    left: 1rem;
    .close {
      &::before {
        top: -5px;
        transform: rotate(55deg);
      }
      &::after {
        top: 90%;
        transform: rotate(35deg);
      }
    }
  }
}
#pdf-viewer {
  .ReactModal__Overlay {
    z-index: 11;
  }
}
#topicViewer {
  .topic-subject {
    padding: 1.5rem;
    border-bottom: 1px solid #eee;
    background-color: #F7F7F7;
  }
  .avatar-by-name {
    font-size: 1.5rem;
    font-family: $h-font-family;
    font-weight: bold;
  }
  .topic-avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    box-shadow: $box-shadow;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .topic-infos {
    padding-left: 3rem;
  }
  .topic-date {
    font-weight: normal;
    font-size: .8rem;
  }
  .topic-user {
    font-weight: bold;
    margin-right: .75rem;
    font-size: .9rem;
  }
  .topic-meta {
    display: flex;
    font-family: $h-font-family;
    justify-content: space-between;
    padding-bottom: 1rem;
    font-weight: bold;
  }
  .topic-tags {
    display: flex;
    font-family: $h-font-family;
    text-transform: uppercase;
    font-weight: 600;
    li {
      background-color: #eee;
      margin-right: .5rem;
      padding: .35rem .75rem;
      font-size: .7rem;
    }
  }
  .topic-comments {
    padding: 1rem 0 1rem 2rem;
    .topic-infos {
      padding: 1rem 0 1rem 2rem;
    }
  }
  .topic-comment {
    &:nth-child(even) .comment-infos {
      background-color: #e5e5e5;
    }
    &:nth-child(odd) .comment-infos {
      background-color: #f5f5f5;
    }
  }
  .comment-infos {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem 0;

    &:before {
      content: "";
      position: absolute;
      height: 100;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: $portal-color;
    }
    .topic-avatar {
      border: 2px solid $portal-color;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .PostUser-avatar {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
      }
    }
  }
  .topic-comment-date {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    background-color: $portal-color;
    color: #fff;
    padding: .5rem;
    border-radius: 50%;
    font-size: .7rem;
    width: 3.6rem;
    height: 3.6rem;
    .line-hour {
      font-weight: bold;
      font-size: .8rem;
    }
    .line-month {
      font-family: $h-font-family;
    }
  }
  .topic-reply {
    position: relative;
    padding: 2rem 0 0;
  }
  .reply-form {
    position: fixed;
    width: inherit;
    padding: 1rem;
    box-shadow: $main-box-shadow;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    textarea {
      border: 1px solid $portal-color;
      box-shadow: none;
      border-radius: 4px;
      color: #8d8d8d;
      font-size: .8rem;
    }
    button {
      color: #fff;
      font-family: $h-font-family;
      background-color: $portal-color;
      border-radius: 4px;
      padding: .5rem 2rem;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
#comment_input {
  position: relative;
  &.loading {
    &:before {
      content: "";
      background-color: rgba(255, 255, 255, .6);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
.pdf-close {
  background-color: #484848;
  button {
    &:before, &:after {
      background-color: #fff;
    }
  }
}
.comment-topic {
  padding: 0 5rem;
  & > div {
    padding: 0;
    border-radius: 5px;
    padding: .5rem 0;
  }
  textarea {
    background-color: $new-light-gray;
    box-shadow: none;
    padding-left: 5rem;
    &:focus {
      border: none;
    }
    & + div {
      width: 2.857rem;
      height: 2.857rem;
      left: 1.5rem;
      top: 0;
    }
  }
}
