.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.weight_400 {
  font-weight: 400;
}

.form_actions {
  position: absolute;
  background: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -2px 10px rgba(41, 57, 77, 0.1);
  z-index: 100;
  border-radius: 0px 0px 5px 5px;
}

.step_content {
  overflow-y: auto;
  height: 100%;

  .org_options {
    width: 100%;
    overflow-y: auto;
    //max-height: 310px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f8f9fa;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6dbe0;
      border-radius: 100px;
    }
  }
}

.acceptConditions {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3c4e64;

  a {
    color: #f7953e;
    // text-decoration: underline;
  }
}

.register_btn {
  &:hover {
    border-color: #f7953e !important;
  }
}
