$planWith: 154px;
$planSpacing: 4px;

.content {
  padding: 1rem;
}

.planList {
  padding: 1rem;

  .title {
    font-weight: normal;
    font-size: 14px;
    color: #29394d;
    margin-bottom: 1rem;

    span {
      margin-left: 0.5rem;
      font-weight: normal;
      font-size: 12px;
      color: #6d7f92;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    transition: 0.3s margin ease;
    margin: 0 auto;
    width: unset;

    // hack to make a DIV fill an entire table cell
    tr {
      height: 1px;

      .cell {
        height: inherit;
        padding: 0;

        .cwrapper {
          // height: 100%;
          min-height: 39px;
          cursor: pointer;
        }
      }
    }

    td,
    th {
      &:not(:first-child):not(:last-child) {
        .cwrapper {
          margin: 0 $planSpacing;
        }
      }

      &:last-child {
        .cwrapper {
          margin-left: $planSpacing;
        }
      }
    }

    thead {
      background: none;
      border: none;

      .cellTitle {
        vertical-align: top;
      }

      .cwrapper {
        background: #f8f9fa;
        border-radius: 5px 5px 0 0;
        border: 1px solid #d6dbe0;
        border-bottom: 0;
        text-align: center;
        padding: 1rem 0.5rem;
        position: relative;
        z-index: 0; // used to prevent text from covering onboarding step bullet

        .infos {
          position: relative;
          z-index: 1;

          .plan_name {
            line-height: 21px;
            height: 42px;
          }
        }

        h3 {
          color: #000000;
        }

        .date {
          font-weight: 500;
          font-size: 14px;
          color: #29394d;

          position: absolute;
          top: 0;
          left: -1px;
          width: calc(100% + 2px);
          background: #f1f2f4;
          border-radius: 5px 5px 0px 0px;
          transform: translateY(-87%);
          z-index: -1;
          padding: 0.3rem;
          padding-bottom: 0.5rem;
        }

        .price {
          padding: 0.25rem 0.75rem;
          background: #f1f2f4;
          border-radius: 3px;
          font-weight: 500;
          font-size: 14px;
          color: #29394d;

          &:not(:last-child) {
            margin-right: 0.5rem;
          }

          &.red {
            background: #ffdae0 !important;
          }

          &.barred {
            position: relative;

            &::after {
              content: '';
              background: #fe3745;
              position: absolute;
              border-radius: 5px;
              height: 2px;
              width: 80%;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%) rotate(-20deg);
            }
          }
        }

        .radioButton {
          width: 22px;
          height: 22px;
          background: #f1f2f4;
          border: 2px solid #b2bcc6;
          border-radius: 50%;
          display: inline-block;
          margin-top: 0.5rem;

          &.active {
            background: #18a0fb;
            border: 2px solid #ffffff;
          }
        }

        .ctriangle {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 35px;
          z-index: 0;
          background: #fff;
          overflow: hidden;

          &::before {
            content: '';
            position: absolute;
            display: inline-block;
            width: 90px;
            height: 35px;
            background: #f8f9fa;
            left: 0;
            transform: rotateZ(15deg) translateX(-10px) translateY(-9px);
          }

          &::after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 90px;
            height: 35px;
            right: 0;
            background: #f8f9fa;
            transform: rotateZ(-15deg) translateX(10px) translateY(-9px);
          }
        }
      }

      th {
        &:not(:first-child) {
          width: ($planWith + 2 * $planSpacing);
          min-width: ($planWith + 2 * $planSpacing);
        }
        &:last-child {
          width: ($planWith + $planSpacing);
          min-width: ($planWith + $planSpacing);
        }
      }
    }

    tbody {
      td {
        font-weight: normal;
        font-size: 14px;
        color: #29394d;
      }

      tr {
        &:nth-child(even) {
          background: #f8f9fa;
          border-radius: 1px;

          .cwrapper {
            background: #f8f9fa;
          }
        }

        .cwrapper {
          position: relative;
          // z-index: 1; // used to hide extra options arrow
          background: #fff;
          border: 1px solid #d6dbe0;
          border-top: 0;
          border-bottom: 0;
        }

        &:last-child {
          .cwrapper {
            border: 1px solid #d6dbe0;
            border-radius: 0 0 5px 5px;
            border-top: 0;
          }
        }
      }
    }

    thead,
    tbody {
      tr .cwrapper {
        &.selected {
          border-color: #18a0fb;
        }

        &.header {
          min-height: 179px; // .cwrapper.header

          &.selected {
            border-color: #18a0fb;
            background: #c7e8fe;

            .ctriangle {
              &::after,
              &::before {
                background: #c7e8fe;
              }
            }

            .date {
              background: #c7e8fe;
            }

            .price {
              background: #f3faff;
            }
          }
        }
      }
    }
  }
}

.sold_out_wrapper {
  position: absolute;
  overflow: hidden;
  inset: 0;
  margin: 0 4px;
}

.sold_out {
  height: 24px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fe3745;
  text-transform: uppercase;

  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #fff;

  position: absolute;
  transform: rotate(45deg);
  width: 94px;
  top: 11px;
  right: -23px;
}
