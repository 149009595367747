.options_confirmation {
  flex-direction: column;
  width: 100%;
  display: flex;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
}

.header {
  display: flex;
  align-items: center;
  background: #F3FAFF;
  border-radius: 5px 5px 0px 0px;

  .title {
    padding: 1rem 2rem;
    padding-right: 3rem;
    font-weight: normal;
    font-size: 20px;
    color: #18A0FB;
  }
}

.subheader{
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #29394d;
      margin-bottom: 0.5rem;
    }

    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: #6d7f92;
      margin-bottom: 1rem;
    }
}

.dontShowAgain {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3c4e64;
}