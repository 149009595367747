.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(41, 57, 77, 0.4) !important;
}

.ReactModal__Overlay_DARK {
  inset: 0;
  position: fixed;
  background-color: rgba(41, 57, 77, 0.7) !important;
}

.ReactModal__Content {
  transform: scale(0.1);
}

.ReactModal__Content--after-open {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.ReactModal__Content--before-close {
  transform: scale(0.1);
}

.rmodal {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
  // transition: transform .5s ease-in-out;
  // &.reduce {
  //   transform: scale(.1);
  // }
  &__next,
  &__previous,
  &__close {
    cursor: pointer;
    background-color: $reveal-bg;
    border: 1px solid #fff;
    height: 2.7rem;
    width: 2.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -0.2rem;
  }

  &__close {
    right: -1.125rem;
    z-index: 1;
    width: 2.25rem;
    height: 2.25rem;
    top: -1.125rem;
    background-color: #fff;
    border: 1px solid $new-light-gray;
    border-radius: 50%;
    .close {
      width: 100%;
      height: 100%;
      &:before {
        height: 1.15rem;
        top: calc(50% - 0.575rem);
      }
      &:after {
        width: 1.15rem;
        left: calc(50% - 0.575rem);
      }
    }
  }

  &__next {
    right: 2rem + 3rem;

    .next {
      height: 1.2rem;
      width: 1.25rem;
    }
  }

  &__previous {
    right: 2rem + 6rem;

    .previous {
      height: 1.2rem;
      width: 1.25rem;
    }
  }

  &__body {
    //width: 100rem;
    //height: 20rem;
    //padding: 2rem;
    background-color: $reveal-bg;
    height: 100%;
    //overflow: auto;
    //padding-bottom: 0.5rem;
  }
}
#newArticleViewer {
  width: 100%;
}
#external-url {
  margin-top: 1rem;
}

//@import "component/reveal/media-editor";
//@import "component/reveal/album-editor";
@import 'component/reveal/viewer';
