@import 'util/util.scss';

.circles_container {
  display: flex;
  align-items: center;
  //align-self: self-end;
  color: #6d7f92;

  .circles {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent !important;
      margin-left: 1rem;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 50%;
        width: 2.25rem;
        height: 2.25rem;
        border: 1px solid #d8dde2;
        cursor: pointer;

        &.expanded {
          border-radius: 30px;
          width: unset;
          padding: 0 1rem;
        }

        &.darkBlue {
          border: none;
        }

        &.selected {
          background-color: #18a0fb !important;
          color: #fff;

          &.orange {
            background-color: #f47f4d !important;
          }

          &.purple {
            background-color: #6f6cec !important;
          }

          &.greenTeal {
            background-color: #14B7AD !important;
          }
        }

        &:nth-child(2) {
          margin: 0 0.5rem;
        }
      }
    }
  }
}

// Media queries --------------
@include breakpoint(medium) {
  .circles {
    li {
      font-size: 14px;

      &:hover {
        background-color: #18a0fb !important;
        color: #fff;

        &.orange {
          background-color: #f47f4d !important;
        }

        &.greenTeal {
          background-color: #14B7AD !important;
        }
        
        &.purple {
          background-color: #6f6cec !important;
        }
      }
    }
  }
}
