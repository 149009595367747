@import 'styles/base/variables';
@import 'styles/base/functions';
@import 'styles/base/mixins';
@import 'util/util.scss';

@keyframes moveMenu {
  from {
    top: 0;
  }
  to {
    top: 60px;
  } // height of Menu
}

.submenu,
.watch_submenu {
  position: relative;
  background: $new-title-color;
  height: $subMenuHeight;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: 1000 !important;
  // transition: all 0.2s ease-in-out;

  &.vertical {
    max-width: 15rem;
    flex-direction: column;
    position: absolute;
    padding: 3rem 0;
    height: calc(100vh - 60px);
    & > ul {
      flex-direction: column;
      height: inherit;
      margin-right: 0 !important;

      .left_side {
        flex-direction: column;
      }
      .right_side {
        .nav_right {
          div {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: inline;
              svg * {
                fill: #c7e8fe;
              }
            }
          }
        }

        .shopping_cart {
          .cart_wrapper {
            position: initial;
          }

          button {
            width: 3.5rem;
            justify-content: center;
            transition: width 0.2s ease-in-out;
          }

          .cart_label {
            display: none;
          }

          .cart_content {
            top: unset;
            right: unset;
            bottom: 72px;
            left: 24px;
            margin: -7rem 0rem -1.5rem 3rem;
            width: 3.7rem;
            overflow: hidden;
          }

          .cart_badge {
            right: -5px;
            transition: all 0.45s ease-in-out;
          }
        }
      }
    }
    .flip {
      top: 1rem;
      right: -0.75rem;
      margin: 0;
      position: absolute;

      display: none;
    }
    .nav {
      &:hover {
        border-left: 3px solid $portal-new-blue;
      }
      a {
        padding: 0.875rem 1.25rem !important;
        width: 3.7rem;
        overflow: hidden;
        max-height: 3rem;
      }
      ul a {
        text-transform: none !important;
      }
    }

    &:hover {
      .flip {
        transform: rotate(90deg);
        display: flex;
      }
      .nav {
        a {
          width: 15rem;
          .arrow {
            transform: rotate(-90deg);
          }
        }

        ul {
          left: 14.5rem;
          top: 0;
          z-index: 0;
        }
      }
      .right_side {
        .nav_right {
          div {
            &:first-child {
              display: inline;
            }
            &:last-child {
              display: none;
            }
          }
        }

        .shopping_cart {
          button {
            width: 14rem;
            justify-content: flex-start;
            padding-left: 1.25rem;
          }

          .cart_label {
            display: block;
          }

          .cart_content {
            left: 192px;
          }

          .cart_badge {
            right: 1.25rem;
            top: 9px;
          }
        }
      }
    }

    &:global(.fixed) {
      // margin-top: -5px;
      bottom: 0;
      animation: moveMenu 0.5s;
    }
  }
  & > ul {
    display: flex;
    background: transparent !important;
    height: 100%;
    width: 100%;
    margin-right: 3rem;
    justify-content: space-between;

    .left_side {
      display: flex;
      align-items: center;
    }

    .right_side {
      display: flex;
      align-items: center;
      justify-content: center;

      .nav_right {
        div:last-child {
          display: none;
        }
      }
    }
  }
}

.watch_submenu {
  height: 44px;
  min-height: $subMenuHeight;

  .top_nav {
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  .bottom_nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: 64px;
    margin-bottom: -1px;
    background: $new-title-color;
    padding: 0 20px;
    box-shadow: 0px -10px 10px rgba(9, 22, 38, 0.5);

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      height: 100%;

      .nav_item {
        width: 100%;
        margin: 4px 0 5px 0;

        a,
        .nav_btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: $portal-new-light-blue;
          font-size: 12px;
          font-weight: 400;
          height: 100%;
          width: 100%;
          border-radius: 5px;

          svg {
            margin-bottom: 3px;
          }

          &.active {
            background: $fiord;
          }
        }
      }
    }
  }
}

.flip {
  position: relative;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  background-color: $new-title-color;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  top: 1.35rem;
  margin-right: 2rem;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.nav {
  color: $portal-new-light-blue;
  height: 100%;
  transition: color 0.2s ease-in-out;
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    ul {
      display: flex;
    }
  }
  a,
  i {
    color: $portal-new-light-blue !important;
  }
  i,
  svg {
    margin-right: 1.2rem;
    font-size: 1.2rem;
    flex-shrink: 0;
  }
  a {
    transition: width 0.3s ease-in-out;
    height: 100%;
    width: 100%;
    padding: 0.8rem 1.75rem !important;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: normal;

    &.active {
      background: $fiord;
    }
  }
  .arrow {
    margin-left: 0.75rem;
  }
  ul {
    display: none;
    position: absolute;
    top: $subMenuHeight;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: $new-title-color !important;
    li {
      //height: 2.75rem;
      display: flex;
      align-items: center;
      transition: color 0.2s ease-in-out;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      a {
        text-transform: none;
      }
    }
  }
}
li {
  list-style: none;
}

.nav_btn {
  a,
  .label_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    background-color: #06d9b1;
    color: #fff !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
    padding: 0.6rem 1.2rem !important;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

    &.redOrange {
      background-color: #fb1e5b;
    }
  }

  a {
    &:hover {
      background-color: #02af8e;
    }

    &.redOrange {
      &:hover {
        background-color: #cc1c4d;
      }
    }
  }

  &.active {
    background: $fiord;
  }
}

// Hack to fix the select nav over-height
.select {
  height: inherit;
}

// Media queries --------------------------------

@include breakpoint(medium) {
  .watch_submenu {
    padding: 0 40px 0 0;

    ul {
      margin: 0;

      .left_side {
        .emblem {
          margin: 0 24px;
          width: 30px;
          height: auto;
        }
      }
    }
  }
}

@include breakpoint(large) {
  .watch_submenu {
    padding: 0 80px;

    .emblem {
      display: none;
    }
  }
}
