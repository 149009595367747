@import "styles/base/variables";

.search {
  min-width: 200px;
  height: 32px;
  background: #ffffff;
  border: 0.5px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 30px;
  overflow: hidden;
  padding: 0.2rem 0.5rem;

  display: flex;
  align-items: center;

  input {
    outline: none;
    border: none;
    flex: 1;
    height: 100%;
    width: calc(100% - 16px - 1rem);
    margin-left: 0.5rem;

    &::placeholder {
      color: #b7bdc3;
    }
  }

  .clean {
    cursor: pointer;

    &:hover {
      fill: $cornflower-lilac;
    }
  }
}

@media (max-width:763px) {
  .search {
    display: none;
  }
}
