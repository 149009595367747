.banner {
  background: linear-gradient(0deg, rgb(41, 57, 77) 0%, rgba(41, 57, 77, 0.8) 14.23%, rgba(41, 57, 77, 0) 100%),
  url('/img/premiumOffer-banner.png') !important;

  .titles {
    padding-top: 5rem;

    h1 {
      font-weight: 700;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      width: 800px;
      margin: auto;


      strong {
        color: #fb1e5b;
        font-weight: unset;
      }
    }

    h2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      width: 700px;
      margin: auto;
      text-align: center;
    }

    h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      color: #ffffff;
      margin: auto;
      text-align: center;
    }

  }

  .discover {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #D8DDE2;
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }

  .svg {
    path {
      fill: #FB1E5B;
    }
  }

}

