@import 'util/util.scss';

.modal_content {
  position: absolute;
  background-color: #fff;
  border: none;
  overflow: none;
  padding: 0;
  bottom: unset;
  min-height: 40%;
  z-index: 999;
  width: 95%;
  height: 90vh;
  max-height: 900px;
  // max-height: 95%;
  max-width: 1296px;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
  display: flex;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translateX(-50%) translateY(-50%) !important;
}

// @include breakpoint(small only) {
//   .modal_content {
//     inset: 0; // TODO
//   }
// }

@include breakpoint(medium up) {
  .modal_content {
    border-radius: 5px;
  }
}

@include breakpoint(large up) {
  .modal_content {
    margin: auto;
  }
}
