.tag {
  margin-right: 0.3rem;
  margin-bottom: 0.4rem;
  background: rgba(109, 127, 146, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .tagName {
    padding: 0.25rem 0rem;
    padding-left: 0.43rem;
    padding-right: 0.2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #29394d;
    margin: 0;
  }

  .dismissContainer {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    &:hover {
      background-color: rgb(248, 171, 140);
      cursor: pointer;
      .tagDismiss {
        path {
          stroke: #d90d0d;
        }
      }
    }
    .tagDismiss {
      width: 0.5rem;
      height: 0.9rem;
      margin-top: 0.33rem;
      path {
        stroke: #6d7f92;
      }
    }
  }
}
