@mixin disabled-button($color: $alice-pass, $background: #fff) {
	cursor: not-allowed;
	background: $background !important;
	color: $color;
	// border: none;
	box-shadow: none;

	&:hover {
		background: $background !important;
		color: $color;
	}
}

.tt-button {
	color: $dodger-blue;
	padding: .8rem $spacing-xl;
	cursor: pointer;
	outline: none;
	background: #FFFFFF;
	border: 1px solid rgba(199,232,254,.5);
	box-sizing: border-box;
	box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
	border-radius: 5px;
	align-items: center;

	&__icon {
		margin-right: $spacing-s;
		font-size: .75rem;
	}

	&__text {
		font-family: $main-body-font;
		font-weight: 500;
		font-size: 1rem;
		text-align: center;
	}

	&:hover {
		box-shadow: none;
	}

	// button type rounded
	&.rounded {
		border-radius: 30px;
	}

	&.active {
		box-shadow: none;
		border: none;
	}

	&.disabled {
		@include disabled-button();
	}

	&:not(.disabled) {
		&.blue {
			&.full {
				background: linear-gradient(270deg, #18A0FB 0%, #2495E1 100%);
				color: #fff;
			}

			&:not(.full) {
				color: $dodger-blue;
			}
		}

		&.blue2 {
			&.full {
				background: #2494e1;
				color: #fff;
			}

			&:not(.full) {
				color: $dodger-blue;
			}
		}

		&.blue3 {
			&.full {
				background: #18A0FB;
				color: #fff;

				&:hover {
					background: #2495E1;
				}
			}

			&:not(.full) {
				color: $dodger-blue;
			}
		}

		&.green {
			&.full {
				background: linear-gradient(180deg, #62DEB0 0%, #06D9B1 100%);
				color: #fff;

				&:hover {
					background: #02AF8E;
				}

			}

			&:not(.full) {
				color: $persian-green;
			}
		}

		&.green2 {
			&.full {
				background: #06D9B1;
				color: #FFF;

				&:hover, &.active {
					background: #02AF8E;
				}
			}

			&:not(.full) {
				color: $persian-green;
			}
		}

		&.gray {
			&.full {
				background: rgba(109, 127, 146, 0.6);
				color: #FFF;

				&:hover {
					background: #6D7F92;
				}
			}

			&:not(.full) {
				color: $slate-gray;
				border-color: #D8DDE2;
				&:hover {
					background: #FFF;
					color: $slate-gray;
				}
			}
		}

		&.dark_gray {
			&.full {
				background: #29394D;
				color: #FFF;

				&:hover {
					background: #536070;
				}
			}
		}

		&.red {
			&.full {
				background: #FF636E;
				color: #fff;

				&:hover {
					background: #F4515D;
				}
			}

			&:not(.full) {
				color: #FF636E;
			}
		}
	}
}

.tt-button-group {

	.tt-button-help {
		margin-left: 1rem;
		font-family: Roboto;
		font-size: 14px;
		line-height: 16px;
		color: rgba(109, 127, 146, 0.5);
	}
}

@media only screen and (max-width: 768px) {
	.tt-button-help {
		display: none;
	}
}
