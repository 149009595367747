@import 'styles/base/variables';

.goBackHeader {
  height: $goBackHeaderHeight;
  background: #f3faff;
  width: 100%;
  padding: 0.75rem;
  padding-right: 5.1rem;
  z-index: 700;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.05);

  &.orange {
    background: #fcfaf3;
  }

  &.redOrange {
    background: #fff1f0;

    .title {
      color: #fb1e5b;
    }
  }

  &.isFixed {
    position: fixed;
    top: 0;
  }

  & > * {
    &:first-child {
      margin-left: calc(5.1rem - 0.75rem);
    }
  }

  .goBackButton {
    margin-left: calc(5.1rem - 0.75rem);
    margin-right: 1rem;
    padding: 0.55rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #3c4e64;

    &:hover {
      border-color: #d7dbe0;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #18a0fb;
  }
}
