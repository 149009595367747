.wrapper {
  display: flex;

  .search_input {
    display: flex;
    background: #3c4e64;
    height: 56px;
    flex: 1;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px;

      &.clear_input {
        cursor: pointer;
        visibility: hidden;
        transition: all 0.3s ease;

        &.visible {
          visibility: visible;
        }
      }
    }

    input {
      flex: 1;
      border: none;
      box-shadow: none;
      outline: none;
      background: #3c4e64;
      font-weight: 400;
      font-size: 14px;

      &::placeholder {
        color: #b2bcc6;
      }

      color: #fff;
    }
  }

  .cancel_btn {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    padding: 0 1.5rem;
    background-color: #29394d;
  }
}
