.wrapper {
  position: fixed;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #c7e8fe;
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 360px;
  min-height: 500px;

  .content {
    flex: 1;
    overflow-y: auto;
  }

  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #18a0fb;
  }

  .price_summary {
    font-weight: 500;
    font-size: 14px;
    color: #29394d;
    background: #f3faff;
    border-radius: 0px 0px 4px 4px;

    span:last-child {
      font-size: 16px;
    }
  }
}
