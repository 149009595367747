.editButton {
  cursor: pointer;
  border: 1px solid #d8dde2;
  width: 28px;
  height: 28px;
  border-radius: 50%;

  &:hover {
    background-color: #f1f2f4;
  }
}
