$speakerSize: 50px;

.wrapper {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 1rem;
  min-height: 6rem;
}

.description {
  & > * {
    font-weight: normal;
    font-size: 14px;
    color: #29394d;
  }

  &.blueH {
    h1,
    h2,
    h3,
    h4,
    h6 {
      color: #18a0fb;
    }
  }
}

.register {
  background: #06d9b1;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 0.6rem 1rem;
  cursor: pointer;
  float: right;
  margin: 1rem;

  &:hover {
    background: #02af8e;
    color: #ffffff;
  }
}

.speakers {
  display: flex;
  clear: both;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 1rem;

  .speaker {
    cursor: pointer;
    margin-bottom: 0.5rem;
    margin-top: 35px;
    position: relative;

    .avatar {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .infos {
      text-align: center;
      padding-top: 35px;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      background: #f8f9fa;
      border: 1px solid #f1f2f4;
      border-radius: 5px;
      height: 100%;

      h3 {
        font-weight: 500;
        font-size: 14px;
        color: #29394d;
        margin-bottom: 0;
      }

      h5 {
        font-style: normal;
        font-size: 12px;
        color: #6d7f92;
      }
    }

    &.fetching {
      .infos {
        h3 {
          height: 1rem;
          width: 5rem;
          background: #e8eaed;
        }
        h5 {
          height: 1rem;
          width: 6rem;
          background: #e8eaed;
        }
      }
    }
  }
}

.block {
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
  }
}

.blockSlots {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    span {
      font-weight: 500;
      font-size: 14px;
      color: #29394d;
    }
  }
}

.fetching {
  h3 {
    height: 1rem;
    width: 5rem;
    background: #e8eaed;
  }
  h5 {
    height: 1rem;
    width: 6rem;
    background: #e8eaed;
  }
}

.book {
  font-size: 12px;
  color: #29394d;

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
  }

  .action {
    background: #29394d;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding: 0.5rem 1rem;

    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  img {
    box-shadow: 0px 4px 10px #29394d1a;
    padding-left: 0 !important;
  }
}
