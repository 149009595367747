$blue-color: #18a0fb;
$blue-light-2-color: '#c7e8fe';
$blue-light-3-color: '#f3faff';

$orange-color: #f47f4d;
$orange-light-2-color: #ffe1cc;
$orange-light-3-color: #fcfaf3;

$redOrange-color: #fb1e5b;
$redOrange-light-2-color: #ffe2dc;
$redOrange-light-3-color: #fff1f0;

$purple-color: #6f6cec;
$purple-light-2-color: #d7cfff;
$purple-light-3-color: #f4f2ff;

$main-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$bg-color: var(--bg-color);

$green-teal-color: #14b7ad;
$green-teal-light-color: #6fd3c9;
$green-teal-light-2-color: #ecfcfb;

.guestAddress {
  margin-top: 2px;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #3c4e64;
    margin-bottom: 0.5rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .title {
      margin-bottom: 0;
    }

    .navTo {
      cursor: pointer;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      display: flex;
      align-items: center;

      span {
        transition: 0.3s all ease;
      }

      &:hover {
        color: #3c4e64;

        span {
          transform: scale(1.05);
        }
      }
    }
  }

  .inputLabel {
    width: 150px;
    flex: unset;
  }
}

.address {
  --primary-color: #{$blue-color};
  --secondary-color: #{$blue-light-2-color};
  --bg-color: #{$blue-light-3-color};

  &.orange {
    --primary-color: #{$orange-color};
    --secondary-color: #{$orange-light-2-color};
    --bg-color: #{$orange-light-3-color};
  }
  &.redOrange {
    --primary-color: #{$redOrange-color};
    --secondary-color: #{$redOrange-light-2-color};
    --bg-color: #{$redOrange-light-3-color};
  }
  &.purple {
    --primary-color: #{$purple-color};
    --secondary-color: #{$purple-light-2-color};
    --bg-color: #{$purple-light-3-color};
  }
  &.greenTeal {
    --primary-color: #{$green-teal-color};
    --secondary-color: #{$green-teal-light-color};
    --bg-color: #{$green-teal-light-2-color};
  }

  background: #ffffff;
  border: 1px solid #d6dbe0;
  border-radius: 4px;
  padding: 1rem;
  padding-bottom: 0.75rem;
  transition: 0.3s all ease;

  .switchWrapper {
    font-weight: normal;
    font-size: 12px;
    color: #6d7f92;
  }

  &.active {
    background: $bg-color;
    border: 1px solid $secondary-color;

    .switchWrapper {
      color: $main-color;
    }
  }

  .data {
    font-weight: normal;
    font-size: 12px;
    color: #29394d;
  }

  // &.orange {
  //   &.active {
  //     background: #fcfaf3;
  //     border-color: #ffe1cc;
  //   }
  //   .switchWrapper {
  //     color: #f47f4d;
  //   }
  // }

  .actions {
    & > * {
      cursor: pointer;
    }
  }
}
