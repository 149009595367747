#mainMenu {
  text-transform: uppercase;
  color: $new-title-color;
  font-weight: 500;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  z-index: 900;
  width: 100%;
  // position: fixed;
  // top: 0;

  &.sticky {
    position: fixed;
  }

  &.home-page {
    position: inherit;
  }
  &,
  ul,
  .top-bar {
    background-color: #fff;
  }
  .title-bar {
    background-color: #fff;
  }
  .top-bar {
    padding: 0 1rem 0 1rem;
    width: 100%;
    height: $topBarMenuHeight;
  }
  .menu__link-create {
    font-weight: bold;
    color: $main-blue;
    i {
      margin-right: 0.4rem;
    }
  }
  .boxes__box__logo {
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 80%;
    margin-bottom: 20px;
  }
  nav {
    li {
      //margin: 0 .8rem;
      transition: nth($transitions, 3);
      cursor: pointer;
      .menu__link {
        //padding: 1.8rem 1rem;
        //font-size: 1rem;
        padding: 0 1rem;
        background-color: $new-very-light-gray;
        color: $portal-color;
        /*&.active {
          background-color: $new-very-light-gray;
          color: $portal-color;
        }*/
      }
      &:hover .menu__link {
        color: #2c2b3f;
      }
    }
  }
  .empty-avatar span {
    font-size: 1rem;
  }
  .menu__logo {
    padding: 0.45rem 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 13rem;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
    z-index: 1;
    &.display-settings {
      box-shadow: 1px 0 4px $shadow-color;
    }
    img {
      //width: 3rem;
      height: 3rem;
      margin: 0 0.75rem;
    }
    .name {
      color: $pickled-bluewood;
      font-size: 18px;
      font-family: $main-body-font;
      text-transform: none;
    }
    h2 {
      font-size: 1rem;
      margin: 0;
      font-weight: 600;
      text-transform: capitalize;
    }
    .setting-options {
      position: absolute;
      width: 13rem;
      left: -1rem;
      background: #fff;
      top: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 0.75rem;
      transition: all 0.2s ease-in-out;
      border-radius: 0 0 5px 5px;
      visibility: hidden;
      opacity: 0;
      &.show {
        box-shadow: 0px 4px 4px $shadow-color;
        visibility: visible;
        opacity: 1;
      }
      li {
        padding: 0.75rem 0;
        width: 100%;
        text-align: center;
        border-top: 1px solid $new-very-light-gray;
        &:hover {
          background-color: $new-very-light-gray;
        }
      }
    }
  }
  .menu__icone {
    padding: 1rem;
    font-size: 1.5rem;
    color: $main-blue;
    cursor: pointer;
  }
  // a {
  //   color: $new-title-color;
  //   white-space: normal;
  //   padding: 0;
  //   line-height: 1rem;
  // }
  .menu__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    box-shadow: 0px 4px 4px $shadow-color;
    margin: 0;
    border-radius: 0.2rem;
    border-top-right-radius: 0;
    opacity: 0;
    visibility: hidden;
    transition: nth($transitions, 2);
    //padding: 0 2rem 1rem;
    background: #fff;
    z-index: 5;
    width: 24rem;
    .menu__dropdown-top {
      padding: 1rem 0;
      border-bottom: 1px solid rgba(44, 43, 63, 0.1);
      display: flex;
      justify-content: space-between;
      span {
        margin: 0;
        color: #2c2b3f;
      }
      .seeAll {
        transition: nth($transitions, 2);
        color: #95959f;
        font-size: 0.7rem;
        cursor: pointer;
        &:hover {
          color: #2c2b3f;
        }
      }
    }
  }
  .menu__img,
  .menu__has-child {
    .menu__link {
      /*      padding-bottom: 1.7rem;*/
      //padding-bottom: 1.8rem;
      line-height: 1;
      display: inline-block;
      //padding: 1.46rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 15rem;
      height: $topBarMenuHeight;
      /*i {
        position: absolute;
        right: 1rem;
      }*/
    }
    .menu__dropdown {
      padding-top: 1rem;
      width: 15rem;
      li {
        //margin: 1rem 0;
        transition: nth($transitions, 2);
        &:hover > a {
          color: #2c2b3f;
        }
      }
    }
  }

  .menu__has-child {
    &.menu__community {
      &:hover {
        & > a {
          background-color: #fff;
          color: $portal-new-blue;
          box-shadow: 0px 4px 4px $shadow-color;
          & > i {
            transition: transform 0.2s ease-in-out;
            transform: rotate(180deg);
          }
        }
      }
      .menu__sub-child {
        border-top: 1px solid $new-very-light-gray;
        padding: 0.75rem 0;
        text-align: center;
        &:hover {
          background-color: $new-very-light-gray;
          a {
            font-weight: 600;
            color: $portal-new-blue;
          }
        }
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .menu__dropdown {
        width: 15rem;
        padding-top: 0;
        max-height: 36rem;
        overflow-y: auto;
      }
    }
    &.menu__author .empty-avatar span {
      color: #fff;
      margin: 0;
    }
    .menu__dropdown {
      width: 24rem;
      /*top: 2.92rem;*/
      left: 0;
      border-top-left-radius: 0;
      padding-bottom: 0;

      .menu__dropdown-top {
        border-bottom: none;
        border-top: 1px solid rgba(44, 43, 63, 0.1);
      }
    }

    .menu__sub-child {
      &.hidedItem {
        display: none;
        transition: none;
      }
      display: flex;

      .first-letter {
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
    &:hover .menu__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
  .menu__community,
  .menu__category {
    .menu__dropdown ul {
      text-transform: none;
      margin: 0;
      border-radius: 0 0 5px 5px;
      a {
        padding: 0;
        width: 100%;
        display: inline-block;
        font-size: 0.8rem;
        text-transform: uppercase;
        font-family: $h-font-family;
        font-weight: 400;
        letter-spacing: 0.06rem;
      }
    }
  }
  .menu__img-wrap {
    width: 2.8rem;
    border-radius: 3rem;
    //overflow: hidden;
    height: 2.8rem;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }
  .top-bar-right {
    display: flex;
    /*&.menu__off .menu__language {
      display: flex;
    }*/
    .menu__language {
      //margin: 0;
      //padding: 1rem;
      //box-shadow: $shadow-like-border;
      display: flex;
      ul {
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-weight: 500;
        margin-right: 0.5rem;
        li {
          border-radius: 50%;
          width: 2.5rem;
          height: 2.5rem;
          color: $portal-new-blue;
          border: 1px solid $portal-new-light-blue;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0.5rem;
          cursor: pointer;
          transition: 0.2s font-size ease-in-out;
          &:hover,
          &.selected {
            background-color: $portal-new-blue;
            color: #fff;
          }
        }
      }
    }
    .menu {
      //margin-right: 1rem;
      .menu__img {
        margin: 0 0.3rem 0 1.5rem;
      }
      & > li {
        margin: 0 0.1rem;
        //position: relative;
        //border-radius: 2rem 2rem 0 0;
        transition: nth($transitions, 2);
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        cursor: pointer;
        & > span {
          font-size: 1.3rem;
          &.dotted:before {
            top: 21%;
            right: 27%;
          }
        }
        &:hover {
          /*&:before {
            content: ' ';
            position: absolute;
            height: 0.5rem;
            background-color: #fff;
            bottom: 0px;
            left: 0;
            right: 0px;
            z-index: 6;
          }*/
          //box-shadow: 0 0 0.9rem 0.05rem rgba(44,43,63,.1), 0 0 0 0.05rem rgba(44,43,63,.13);
          background-color: $new-light-gray;
          .menu__dropdown {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .sign-in {
      padding: 1.45rem 1rem;
      display: inline-block;
      font-weight: 600;
      transition: color 0.2s ease-in-out;
      color: $new-title-color;
      font-weight: 500;
      text-transform: none;
      font-size: 0.9rem;
      line-height: 1;
      i {
        margin-right: 0.75rem;
        position: relative;
        top: 1px;
      }
      &:hover {
        color: $main-blue;
      }
    }
    .social-links__wrapper {
      top: calc(100% + 0.5rem);
      right: -5rem;
      padding: 0;
      width: 20rem;
      background: $new-light-gray;
      position: absolute;
      box-shadow: 0px 4px 4px $shadow-color;
      border-radius: 0px 0px 4px 4px;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s all ease-in-out;
      z-index: 5;
      cursor: default;
      .social-links__header {
        background: $new-light-gray;
        font-size: 0.875rem;
        text-align: center;
        color: $portal-new-blue;
        padding: 0.75rem 1rem 0.25rem 1rem;
        text-transform: none;
        font-weight: 500;
        .menu__img-wrap {
          width: 2.5rem;
          height: 2.5rem;
        }
        .infos {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          color: $portal-color;
          font-size: 0.8rem;
          font-weight: 600;
          margin-left: 0.75rem;
          .email {
            font-size: 0.7rem;
            color: $new-gray;
          }
        }
      }
      .social-links__body {
        border: 0.25rem solid $new-light-gray;
        text-transform: none;
        font-size: 0.7rem;
        & > ul {
          padding: 0.75rem;
          & > li {
            padding: 0;

            & > a {
              padding: 1rem 1.5rem 0.25rem;
              border-top: 1px solid $new-very-light-gray;
              font-size: 0.75rem;
              position: relative;
            }
            & > .enabled {
              box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.05);
              border: 1px solid #eef6ff;
              &:hover {
                background-color: $bg-new-light-blue;
                span,
                .app-name {
                  color: $portal-new-blue;
                }
              }
            }
            & > .disabled {
              opacity: 0.35;
              cursor: inherit;
            }
            .infos {
              text-align: right;
              font-size: 0.6rem;
              font-weight: normal;
              color: $new-gray;
              margin-top: 0.25rem;
            }
          }
        }
      }

      .small-up-3 > li.cell {
        padding: 0.5rem;
        .app-wrapper {
          padding: 0.3rem;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          img {
            width: 2.7rem;
            height: 2.7rem;
            margin-bottom: 0.25rem;
          }
        }
      }
    }
    .notifications {
      position: relative;
    }
  }

  .menu__notifs-contents,
  .menu__messages-contents {
    margin: 0;

    li {
      margin: 1.5rem 0;
      & > a {
        display: flex;
        text-transform: initial;
        transition: nth($transitions, 2);
        position: relative;
        &:hover {
          color: #2c2b3f;
        }
      }
      span {
        margin-right: 1rem;
        font-size: 1.3rem;
        color: $main-blue;
      }
      p {
        margin: 0.2rem 0 0 0;
        strong {
          margin: 0 0.3rem;
        }
      }
    }
    .menu__notifs-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 86%;
      span {
        font-size: 0.8rem;
        color: #bbb;
        font-family: $h-font-family;
      }
    }
  }
  .menu__messages-contents {
    .menu__img-wrap {
      margin-right: 1rem;
    }
    h4 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 0.8rem;
      color: #999;
    }
  }

  .menu__messages > span.dotted:before {
    top: 24%;
    right: 19%;
  }
  .expandable {
    &.profile {
      .triangle:before {
        bottom: -0.5rem;
      }
      .social-links__wrapper {
        right: -0.3rem;
        .menu__language {
          padding: 1.25rem 0;
          ul {
            width: 100%;
            margin: 0;
          }
          li {
            font-size: 0.8rem;
            padding: 0;
          }
        }
        .menu__social {
          padding: 1.25rem 0;
          ul {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
              font-size: 1.37rem;
              a {
                width: 2.2rem;
                height: 2.2rem;
                border-radius: 50%;
                transition: background-color 0.2s ease-in-out;
                color: $new-gray;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background-color: $new-light-gray;
                }
              }
            }
          }
        }
      }
    }
    .sub-menu__dropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > li {
        width: 100%;
        //border-top: 1px solid $new-very-light-gray;
      }
    }

    & > .triangle {
      position: relative;
      &:before {
        //content: ' ';
        position: absolute;
        bottom: -1.25rem;
        left: calc(50% - 0.5rem);
        z-index: 6;
        background: transparent;
        border-right: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-bottom: 0.5rem solid $new-light-gray;
      }
    }
    &:hover {
      .social-links__wrapper {
        opacity: 1;
        visibility: visible;
      }
      & > .triangle:before {
        content: '';
      }
    }
  }
  .social-links {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    li {
      padding: 0 0.6rem;
      transition: nth($transitions, 3);
      &:hover a {
        color: $h-color;
      }
    }
    .social-links__footer {
      font-size: 0.9rem;
      text-align: center;
      padding: 1rem 0;
      a {
        color: $portal-new-blue;
      }
    }
  }
  /*.ebox {
    a {
      transition: box-shadow .3s ease-in-out;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $portal-color;
      &:hover {
        box-shadow: 0 0 0.9rem 0.05rem rgba(44,43,63,.1), 0 0 0 0.05rem rgba(44,43,63,.13);
      }
    }
    img {
      width: 2rem;
      position: relative;
      top: -0.1rem;
    }
  }*/
}

@include mui-series {
  #mac-area {
    @include mui-queue(2s, 0s, slide($direction: top));
    animation-fill-mode: backwards;
  }
  #iphone-area {
    @include mui-queue(1s, 0s, slide($direction: left));
    animation-fill-mode: backwards;
  }
}

.dotted:before {
  content: '';
  border-radius: 2rem;
  background-color: $main-orange;
  top: 22%;
  right: 0;
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  border: 0.15rem solid #fff;
}

// Responsive menu
// #mainMenu {
//   .top-bar {
//     @include breakpoint(medium only) {
//       padding: 0 1rem;

//       .menu__logo {
//         display: inline-block;
//         width: 38px;
//       }

//       .menu__img {
//         margin: 0;
//       }
//     }

//     @include breakpoint(small only) {
//       flex-wrap: wrap;
//       padding: 1rem;
//     }

//     // special case not covered by foundation breakpoints
//     @media screen and (min-width: rem-calc(640px)) and (max-width: rem-calc(740px)) {
//       .menu__logo {
//         width: 30px;
//       }
//       .menu li {
//         margin: 0 .8rem 0 0;
//         font-size: 0.7rem;

//         .menu__link {
//           padding-left: 0.2rem;

//           .icon.icon-arrow-down {
//             left: 0.2rem;
//           }
//         }
//       }
//       .top-bar-right {
//         .social {
//           padding-left: 0;
//           font-size: 0.8rem;
//         }
//       }
//     }

//     .show-menu {
//       font-size: 1.5rem;
//       color: $main-color;
//     }

//     // Invisible checkbox hack to toggle menu
//     input[type=checkbox]#show-menu{
//       display: none;
//     }

//     // Show menu when invisible checkbox is checked
//     input[type=checkbox]#show-menu:checked {
//       ~ .top-bar-right, ~ .top-bar-left {
//         visibility: visible;
//         opacity: 1;
//         max-height: 1000px;

//         transition: nth($transitions, 6);
//       }
//     }

//     // Hide the menu by default on small screens ( we don't use display none, to allow animation)
//     .top-bar-right, .top-bar-left {
//       @include breakpoint(small only) {
//         visibility: hidden;
//         opacity: 0;
//         max-height: 0;

//         transition: nth($transitions, 6);
//       }
//     }

//     .top-bar-right {
//       @include breakpoint(medium only) {
//         .menu {
//           margin-right: 0;

//           .menu__img-wrap {
//             width: 1.8rem;
//             height: 1.8rem;
//           }
//         }
//       }

//       @include breakpoint(small only) {
//         flex-direction: column-reverse;
//         align-items: center;
//         width: 100%;
//         flex-basis: 100%;

//         .menu {
//           margin-right: 0;

//           .menu__dropdown {
//             right: 50%;
//             transform: translateX(50%);
//           }
//         }
//       }
//     }

//     .top-bar-left {
//       @include breakpoint(medium only) {
//         .menu li {
//           margin: 0 .8rem 0 0;
//           font-size: 0.75rem;

//           a {
//             line-height: 1;
//           }

//           .menu__link {
//             padding-right: 0;
//           }
//         }
//       }

//       @include breakpoint(small only) {
//         width: 100%;
//         flex-basis: 100%;

//         .menu__dropdown {
//           left: 50%;
//           transform: translateX(-50%);
//           width: 15rem;
//         }

//         .menu {
//           width: 100%;
//           flex-basis: 100%;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//         }
//       }
//     }
//   }
// }
#hubs {
  color: $h-color;
  &:hover {
    .menu__link {
      border-bottom: 2px solid;
    }
  }
  i {
    font-size: 1rem;
  }
  a {
    color: $portal-color;
    font-weight: 500 !important;
    text-transform: capitalize !important;
  }
}
#portal-switcher {
  display: flex;
  align-items: center;
  position: relative;
  left: 1.25rem;
  span {
    border-radius: 2rem;
    text-transform: initial;
    position: relative;
    cursor: pointer;
    transition: color 0.2s ease-in;
  }
  .left-switch {
    left: 2.5rem;
    padding: 0.6rem 4rem 0.6rem 1.5rem;
  }
  .right-switch {
    right: 2.5rem;
    padding: 0.6rem 1.5em 0.6rem 4rem;
  }
  .desactivated {
    background: $new-light-gray;
  }
  .activated {
    color: #fff;
    background: $new-title-color;
    z-index: 1;
    font-weight: 600;
  }
  img {
    position: relative;
    z-index: 2;
  }
}

.sticky {
  position: fixed !important;
  top: 0 !important;
}
.fixed {
  position: fixed !important;
}

.static {
  top: auto !important;
  position: static !important;
}

.visibility-hidden {
  visibility: hidden;
}

#headerSpaceMaintainer {
  min-height: $topBarMenuHeight;

  &.wsm {
    $wsmHeight: $topBarMenuHeight + $subMenuHeight;
    min-height: $wsmHeight;

    &.wnoIE {
      min-height: $wsmHeight + $noIEHeight;
    }
  }

  &.wgb {
    $wgbHeight: $topBarMenuHeight + $goBackHeaderHeight;
    min-height: $wgbHeight;

    &.wnoIE {
      min-height: $wgbHeight + $noIEHeight;
    }
  }

  &.wnoIE {
    min-height: $topBarMenuHeight + $noIEHeight;
  }

  &.wsm.wgb {
    $height: $topBarMenuHeight + $subMenuHeight + $goBackHeaderHeight;
    min-height: $height;

    &.wnoIE {
      min-height: $height + $noIEHeight;
    }
  }
}

.ttp_header_padding_fix {
  header {
    padding-right: 1.5rem;
  }
}

.ttp_header_avatar_fix {
  header > div:nth-child(2) > ul:nth-child(2) > li > div > div {
    margin: 0;
  }
}
