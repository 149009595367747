.premium {
  transform: matrix(0.66, 0.76, -0.75, 0.66, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 4px 1rem;
  background: #FB1E5B;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
