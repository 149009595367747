$blue-color: #18a0fb;
$orange-color: #f7953e;
$redOrange-color: #fb1e5b;

$main-color: var(--primary-color);

.onboarding {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  background: #29394d;
  border-radius: 5px 5px 0px 0px;

  .title {
    padding: 1rem 2rem;
    padding-right: 3rem;
    font-weight: normal;
    font-size: 20px;
    color: #fffaf3;
  }
}

.navs {
  --primary-color: #{$blue-color};

  &.orange {
    --primary-color: #{$orange-color};
  }

  &.redOrange {
    --primary-color: #{$redOrange-color};
  }

  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;

  li,
  a {
    position: relative;
    padding: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;
    text-align: center;
    color: #b2bcc6;

    height: 100%;
    align-items: center;
    display: flex;

    &::after,
    &::before {
      width: 50%;
      height: 4px;
      background: $main-color;
      transform: translateY(50%);
      z-index: 0;
    }

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: #b2bcc6;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      background: #b2bcc6;
    }

    &.activeNav,
    &.done {
      color: #fff;
      .circle {
        background: #ffffff;
        border: 4px solid $main-color;
      }

      &:not(:first-child)::before {
        background: $main-color;
      }
    }

    &.done {
      color: $main-color;

      .circle {
        background: $main-color;
      }

      &:not(:last-child)::after {
        background: $main-color;
      }
    }

    &.disabled {
      color: #6d7f92 !important;
      cursor: not-allowed !important;
    }

    .circle {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 26px;
      height: 26px;
      background: #b2bcc6;
      border: 4px solid #ffffff;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
      border-radius: 50%;
    }
  }
}
