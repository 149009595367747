$blue-color: #18a0fb;
$blue-light-color: #f3faff;
$blue-light-2-color: '#c7e8fe';

$orange-color: #f47f4d;
$orange-light-color: #f6d9cd;
$orange-light-2-color: #ffe1cc;

$redOrange-color: #fb1e5b;
$redOrange-light-color: #ffabc2;
$redOrange-light-2-color: #ffe2dc;

$purple-color: #6F6CEC;
$purple-light-color: #D7CFFF;
$purple-light-2-color: #F4F2FF;
$purple-light-3-color: #f6f5fa;

$main-color: var(--primary-color);
$main-light-color: var(--primary-light-color);
$secondary-color: var(--secondary-color);

.input {
  background: #fff !important;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.wrapper {
  background: #f8f9fa;
  padding: 2rem;
}

.helperText {
  font-weight: normal;
  font-size: 12px;
  color: #3c4e64;

  &.lighter {
    color: #6d7f92;
  }
}

.coupons_list {
  display: inline-block;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    left: -1rem;
    top: 0;
    height: 100%;
    width: 1px;
    background: #d6dbe0;
  }
}

.coupon_wrapper {
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.coupon {
  --primary-color: #{$blue-color};
  --primary-light-color: #{$blue-light-color};
  --secondary-color: #{$blue-light-2-color};

  &.orange {
    --primary-color: #{$orange-color};
    --primary-light-color: #{$orange-light-color};
    --secondary-color: #{$orange-light-2-color};
  }
  &.redOrange {
    --primary-color: #{$redOrange-color};
    --primary-light-color: #{$redOrange-light-color};
    --secondary-color: #{$redOrange-light-2-color};
  }

  &.purple {
    --primary-color: #{$purple-color};
    --primary-light-color: #{$purple-light-color};
    --secondary-color: #{$purple-light-2-color};
  }

  border: 1px solid #b2bcc6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 34px;
  font-weight: 500;
  font-size: 12px;
  color: #29394d;
  max-width: calc(100% - 22px);

  button {
    border: 1px solid $main-color;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 12px;
    color: $main-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: calc(100% + 2px);
    margin-right: -1px;

    span {
      margin: 0.2rem 1rem;
    }

    &:hover {
      background: $main-light-color;
    }
  }

  &.active {
    background: $main-light-color;
    border: 1px solid $secondary-color;

    button {
      width: 30px;
      height: 30px;
      background: $secondary-color;
      border-radius: 4px;
      border-color: $secondary-color;
      cursor: default;
    }
  }

  // &.orange {
  //   button {
  //     border-color: #f47f4d;
  //     color: #f47f4d;

  //     &:hover {
  //       background: #ffe1cc;
  //     }
  //   }

  //   &.active {
  //     background: #fcfaf3;
  //     border: 1px solid #ffe1cc;

  //     button {
  //       width: 30px;
  //       height: 30px;
  //       background: #ffe1cc;
  //       border-radius: 4px;
  //       border-color: #ffe1cc;
  //       cursor: default;
  //     }
  //   }
  // }
}
