.priceWrapper {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }

  .text {
    font-weight: 500;
    font-size: 12px;
    color: #fe3745;
  }

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.price {
  padding: 0.5rem 1rem;
  background: #f1f2f4;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  color: #29394d;

  &.large {
    padding: 0.5rem 1rem;
    font-size: 14px;
  }

  &.xlarge {
    height: 36px;
    display: inline-block;
    padding: 0.2rem 1rem;
    font-weight: 500;
    font-size: 20px;
  }

  &.medium {
    padding: 0.1rem 0.5rem;
    font-size: 14px;
    line-height: 1.2;
  }

  &.small {
    padding: 0.1rem 0.5rem;
    font-size: 12px;
    line-height: 1;
  }
}

.originalPrice {
  background-color: #fdacad !important;
  position: relative;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   height: 2px;
  //   width: calc(100% + 20px);
  //   background: #fe3745;
  //   border-radius: 2px;
  //   left: -(20px / 2);
  //   top: calc(50% - (2px / 2));
  //   transform: rotateZ(-28deg);
  // }

  @extend .priceOff;
}

.priceOff {
  position: relative;
  color: #29394d;

  &::after {
    position: absolute;
    content: '';
    width: 120%;
    height: 1px;
    border-radius: 2px;
    background: #fe3745;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(-35deg);
  }

  &.xlarge {
    font-size: 16px;
  }

  &.large,
  &.xlarge {
    &::after {
      height: 2px;
    }
  }
}

.priceOffPremium {
  position: relative;
  color: #29394d;

  &::after {
    position: absolute;
    content: '';
    width: 120%;
    height: 1px;
    border-radius: 2px;
    background: black;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(-25deg);
  }

  &.xlarge {
    font-size: 16px;
  }

  &.large,
  &.xlarge {
    &::after {
      height: 2px;
    }
  }
}

.separator {
  font-weight: normal;
  font-size: 14px;
  color: #29394d;
  line-height: 1.2;

  &.xlarge {
    font-size: 16px;
  }

  &.medium,
  &.large {
    font-size: 14px;
  }

  &.small {
    font-size: 12px;
  }
}