.avatarContainer {
  display: flex;
}

.avatar {
   display: inline-block;
    //width: 60px;
    //height: 60px;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 50%;
    background: linear-gradient(180deg, #18A0FB 0%, #06D9B1 100%);

    .image{
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      margin: 3px;
    }

  span {
    font-size: 23px;
    font-weight: 500;
    color: #fff;
  }
  
}


.avatarInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
}

.avatarName {
  font-size: 20px;
  line-height: 15px;
  font-weight: normal;
  color: #3c4e64;
}

.avatarSignature {
  font-size: 16px;
  line-height: 1.1rem;
  font-weight: normal;
  color: #6d7f92;
  margin-top: 2px;
}

@media screen and (max-width: 767px) {

  .avatar {
     margin-right: 5px;  
 }

}