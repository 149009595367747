.title {
  color: #29394d;
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 37px;
    text-transform: uppercase;
    color: #29394d;
  }
}

.partnersWrapper {
  display: flex;
  align-items: center;
  margin: 0 -0.3rem;

  & > * {
    padding: 0 0.3rem;
    flex: 1;
  }

  .logoWrapper {
    background: #fff;
    border: 1px solid #f1f2f4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0 -9px; // Reduce grid-x gutters
    transition: all 0.2s ease-in-out;

    span {
      background-color: #fff;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    // // Reduce grid-x gutters
    // :first-child .logoWrapper {
    //   margin: 0 -9px 0 0;
    // }

    // :last-child .logoWrapper {
    //   margin: 0 0 0 -9px;
    // }

    &:hover {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    }
  }
}
