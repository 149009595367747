.gradiant {
  background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
  background-size: 300% 100%;
}
//#feed-fetching-list {
  .feed-fetching {
    transition: nth($transitions, 3);
    background-color: #fff;
    margin-bottom: 1.5rem;
    &__img {
      height: 13rem;
    }
    &__content {
        padding-left: 13%;
        padding-right: 13%;
        padding-top: 32px;
        box-shadow: 0px 15px 45px -9px rgba(0, 0, 0, 0.2);
    }
    &__username {
      margin-bottom: .4rem;
      height: .9rem;
    }
    &__published-date {
      height: .6rem;
    }
    &__user {
      margin-bottom: 1rem;
    }
    &__title {
      margin-bottom: 1rem
    }
    &__title div {
      margin-bottom: 0.5rem;
      height: 1.3rem;
    }
    &__content-p {
      margin-bottom: 2rem;
      & > div {
        margin-bottom: 0.5rem;
        height: .8rem;
      }
    }
    &__tags {
      padding: 2rem 0 2rem 18%;
      margin-left: -18%;
      border-top: 1px solid #e9e9e9;
      position: relative;
      & .small-4 {
        margin-right: .5rem;
        height: 1rem;
      }
    }
    &__category {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 7rem;
      background-color: #fff;
      padding: 0.3rem 0.5rem;
      div {
        padding: .7rem;
        border-radius: 100px;
      }
    }
    .icon {
      font-size: 16px;
      position: absolute;
      left: 7%;
      top: 2rem;
    }
  }
//}
.category-fetching {
  li.row {
    margin: 0;
  }
  &__name, &__count {
    height: 1.25rem;
  }
  &__count {
    border-radius: 50px;
  }
}
.tag-fetching {
  li {
    margin: 0;
    height: 2rem;
  }
}
.setting-fetching{
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  .user-fetching {
    width: 33.33%;
  }
}
.user-fetching {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .user-avatar {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  h3 {
    height: 1.3rem;
    width: 60%;
    margin-bottom: .7rem;
  }
  h4 {
    height: 1rem;
    width: 70%;
    margin-bottom: .7rem;
  }
  span {
    width: 20%;
    height: 1px;
    background-color: $main-orange;
    margin-bottom: .7rem;
  }
  h5 {
    height: .8rem;
    width: 50%;
  }
}
//#latest-media-fetching {
  .latest-media-fetching {
    //height: 15rem;
    background-color: #E5E5E5;
    margin-bottom: 1.5rem;

  }
//}
.topic-fetching {
  height: 22rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  &__author {
    height: 1.2rem;
    width: 60%;
    margin-bottom: .5rem;
  }
  &__avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin-bottom: 1.5rem;
  }
  &__date {
    height: 1rem;
    width: 50%;
    margin-bottom: 2rem;
  }
  &__title {
    height: 1rem;
    margin-bottom: .2rem;
    width: 80%;
  }
  &__btn {
    width: 30%;
    height: 2rem;
    border-radius: 2rem;
    margin-top: 2rem;
  }
}
.fetching-color {
  background-color: #eee;
}


.fetching-v2 {
  .article-fetching {
    border-radius: 5px;
    border: 1px solid $new-very-light-gray;
    padding-left: .9rem;
    &:first-child {
      padding-left: 0;
    }
    &-author-logo {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
    }
    &-author-infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-author-name, &-author-function {
      width: 100%;
      height: .7rem;
    }
    &-img {
      height: 12.6rem;
      width: 100%;
      margin: 1rem 0;
    }
    &-title {
      height: .9rem;
      margin-bottom: .75rem;
      width: 100%;
      &.last {
      }
    }
    .last {
      width: 80%;
      margin-bottom: 1rem;
    }
    &-text {
      height: .7rem;
      margin-bottom: .5rem;
      width: 100%;
    }
    &-social {
      display: flex;
    }
    &-btn {
      width: 2.7rem;
      height: 2.7rem;
      border-radius: 5px;
      margin: 0 .2rem;
    }
  }
}
#new-community-workflow-fetching {
  .article-fetching {
    height: 15rem;
    margin-bottom: 2rem;
    &-container {
      display: flex;
      margin-top: .5rem;
      position: relative;
    }
    &-img1 {
      width: 48%;
      margin-right: 2%;
    }
    &-img {
      margin: 0 3% 0 0;
      height: 6rem;
      width: 40%;
    }
    &-content {
      width: 50%;
    }
    .social-absolute {
      position: absolute;
      left: 0;
      bottom: -5.5rem;
    }
  }
}

#new-community-topics-fetching {
  .topic-fetching {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 3rem .4rem 0;
    position: relative;
  }
  .topic-fetching-author {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    position: absolute;
    top: .5rem;
  }
  .topic-fetching-content {
    background-color: #fff;
    height: 17rem;
    border-radius: 5px;
    padding: 2.7rem .8rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    .fetching-text-60 {
      width: 60%;
    }
    .fetching-text-30 {
      width: 30%;
    }
    .fetching-text-40 {
      width: 40%;
    }
    .fetching-text-20 {
      width: 20%;
    }
  }
  .last {
    width: 80%;
    margin-bottom: 1rem;
  }
  .topic-fetching-comment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    width: 100%;
    border-top: 1px solid #eee;
  }
}
#new-community-media-fetching {
  .media-fetching {
    padding: 0 .4rem .85rem;
    & > div {
      width: 100%;
      height: 13rem;
    }
  }
}
#event-fetching {
  & > div {
    height: 20.5rem;
    padding-left: .875rem;
    &:first-child {
      padding-left: 0;
    }
  }
  span {
    height: 100%;
    width: 100%;
    display: block;
    background-color: #fff;
    border-radius: 5px;
  }
}

.event-item-fetching {
  height: 20.5rem;
  padding-left: .875rem;
  &:first-child {
    padding-left: 0;
  }
  span {
    height: 100%;
    width: 100%;
    display: block;
    background-color: #fff;
    border-radius: 5px;
  }
}

#event-header-fetching {
  & > div {
    height: 15.625rem;
    width: 60.625rem;
    margin-left: 3rem;

    &:first-child {
      padding-left: 0;
    }
  }
  span {
    height: 100%;
    width: 100%;
    display: block;
    background-color: #fff;
    border-radius: 5px;
  }
}
