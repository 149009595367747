.eventFreeRegistrationModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
  }

  .descriptionMessage {
    color: #394f67;

    strong {
      font-weight: 700;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 10px;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9626d;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    gap: 4px;

    &.disabled {
      & > * {
        opacity: 0.7;
        cursor: default;
      }
    }

    &:not(.disabled) {
      &:hover {
        background-color: #d93c4d;
      }
    }

    &.validate {
      background: #f9626d;

      &:hover {
        background: #d93c4d;
      }
    }

    &.cancel {
      border: 1px solid #d9d9d9;
      color: #29394d;
      background: #ffffff;

      &:hover {
        border: 1px solid #2495e1;
        color: #2495e1;
        background: #ffffff;
      }
    }
  }
}
