.message {
  color: #29394d;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 2rem;
  text-align: center;
}

.action_btn {
  padding: 0.9rem 1rem;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;

  &.cancel {
    background: #ffffff;
    border: 1px solid #d7dbe0;
    color: #6d7f92;

    &:hover {
      border-color: #b2bcc6;
      background: #f1f2f4;
    }
  }

  &.confirm {
    background: #18a0fb;
    color: #ffffff;

    &:hover {
      background: #2495e1;
    }
  }
}
