#setting-users {
  padding: 4rem 0 6rem;
}
.author {
  &-avatar{
    position: relative;
    .official-actions {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: space-evenly;
      background-color: rgba(0, 0, 0, .2);
      i {
        font-size: 2rem;
        font-weight: bold;
        color: #F5F5F5;
        cursor: pointer;
        &.not-display {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: -.125rem;
            bottom: 0;
            width: 4px;
            background: #F5F5F5;
            left: .9rem;
            transform: rotate(30deg);
            height: 2.25rem;
          }
        }
      }
      select {
        height: 3rem;
        background: transparent;
        color: #F5F5F5;
        font-size: 3rem;
        width: 2rem;
        padding: 0;
        line-height: 1;
        margin: 0;
        border: none;
        text-shadow: 1px 1px 2px rgba(255,255,255,.3);
        font-weight: bold;
        &.two-number {
          width: 3.4rem;
          letter-spacing: -5px;
        }
      }
    }
  }
  .author-header {
    h3 {
      color: $portal-new-blue;
      font-weight: 600;
    }
  }
  .user-slogan {
    width: 75%;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #eee;
    margin-top: .5rem;
    line-height: 1;
    font-style: italic;
    font-size: .9rem;
    color: $new-gray;
    text-align: center;
  }
}
