.wrapper {
  position: relative;
}

.loading {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .counter{
    color: #18A0FB;
    position: absolute;
    margin-top: 13%;
    font-size: larger;
    padding-left: 1%;
  }
}
