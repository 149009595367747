.wrapper {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  display: flex;
}

.profileWrapper {
  // border-right: 1px solid #f1f2f4;
  width: 226px;

  .infos {
    // text-align: center;

    .infos_wrapper {
      max-width: 300px;
      margin: 0 auto;
      padding: 0.5rem 0;
    }

    .avatar {
      margin: 0 auto;
      width: 62px;
      height: 62px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 50%;

      span {
        font-size: 23px;
        font-weight: 500;
        color: #fff;
      }
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      color: #29394d;
    }

    h6 {
      font-weight: normal;
      font-size: 12px;
      color: #29394d;
    }

    span {
      font-weight: normal;
      font-size: 12px;
      color: #6d7f92;
      cursor: pointer;

      &:hover {
        color: #29394d;
      }
    }
  }
}

.rightBorder {
  position: relative;

  &::after {
    content: '';
    right: 0;
    top: 16px;
    bottom: 16px;
    position: absolute;
    width: 1px;
    background: #f1f2f4;
    height: 85%;
  }
}

.field {
  max-width: 300px;
  margin: 1rem auto;
}

.section {
  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.info_message {
  display: flex;
  align-items: flex-start;
  max-width: 300px;
  margin: 1rem auto;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6d7f92;

  :first-child {
    margin-top: 1.5px;
  }
}
