.content {
  padding: 1rem;

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #29394d;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px 5px;

  iframe {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: none;
  }
}
