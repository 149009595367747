.receptionBanner {
  width: 100%;
  padding-bottom: 34%; // image size 882x301
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px 5px 0px 0px;
  flex-shrink: 0;
  position: relative;
}

.labels {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .label {
    border: 1px solid #f1f2f4;
    font-weight: 500;
    font-size: 12px;
    color: #29394d;
    background: #fff;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    margin-right: 0.5rem;
    min-height: 36px;
    display: flex;
    align-items: center;
  }

  & > * {
    margin-bottom: 0.5rem;
    flex-shrink: 0;
  }
}

.extra_labels {
  display: flex;
  margin-left: 0.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-right: 0.5rem;

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 0.75rem;
    background: rgb(24, 160, 251);
    border-radius: 50px;

    &:not(:last-child) {
      margin-right: 0.25rem;
    }

    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
}

.details {
  .header {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #29394d;
  }

  .premium{
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    color: #FB1E5B;
  }

  .subTitle {
    font-weight: normal;
    font-size: 14px;
    color: #29394d;
    line-height: 16px;

    strong,
    b {
      font-size: 14px;
      text-transform: uppercase;
      color: #29394d;
    }
  }

  .infos {
    padding-top: 0.2rem;
    ul li {
      font-weight: normal;
      font-size: 14px;
      color: #29394d;
      line-height: 1.2;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.diffTime {
  font-weight: normal;
  font-size: 12px;
  color: #6d7f92;

  .progress {
    background: #d8dde2;
    height: 4px;
    border-radius: 5px;
    position: relative;
    width: 100%;

    .progressBar {
      display: inline-block;
      background-color: #18a0fb;
      height: 4px;
      position: absolute;
      border-radius: 5px;
      top: 0;
      left: 0;
    }
  }
}

.text {
  font-weight: normal;
  font-size: 14px;
  color: #29394d;
  text-align: center;

  &.red {
    color: #ff5656;
  }
}
