.option_wrapper {
  position: relative;
  //min-width: 832px;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  background: #f8f9fa;
  border: 1px solid #d6dbe0;
  border-radius: 5px;
  transition: 200ms all ease-in-out;

  &:hover,
  &.active {
    background: #fef0e3;
    border-color: #f7953e;
  }

  &.fetching {
    &:hover,
    &.active {
      background: #f8f9fa;
      border-color: #d6dbe0;
    }
  }

  .option_image {
    border-radius: 5px;
    width: 154px;
    height: 136px;

    &.fetching {
      background-color: #e4e6ea;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #29394d;
    text-transform: uppercase;

    &.fetching {
      height: 1.5rem;
      width: 15rem;
      background: #e8eaed;
    }
  }

  .info {
    font-weight: normal;
    font-size: 12px;
    color: #29394d;

    strong {
      font-weight: 500;
    }

    div {
      &.fetching {
        height: 1rem;
        width: 7rem;
        background: #e8eaed;
        margin-bottom: 0.4rem;
      }
    }
  }

  .more_details {
    position: absolute;
    bottom: 1rem;
    right: 1.5rem;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    cursor: pointer;
  }

  .radio_label {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin-right: 0.5rem;
    color: #6d7f92;
    cursor: pointer;

    &.active {
      color: #f7953e;
    }
  }

  .radio_button {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: #f1f2f4;
    border: 2px solid #b2bcc6;
    border-radius: 50%;
    cursor: pointer;
    transition: 50ms all ease-in-out;

    &.active {
      background: #f7953e;
      border: none;
    }
  }

  .option_right {
    @media only screen and (min-width: 1025px) and (max-width: 1300px) {
      padding-left: 2rem;
    }
  }
}

.option_active {
  border-color: #18a0fb;
  background: #f3faff;
}

.right_divider {
  border-right: 1px solid #d8dde2;
}

.text_orange {
  color: #f7953e;
}

.edit_btn {
  &:hover * {
    color: #f7953e !important;
  }

  &:hover svg {
    fill: #f7953e !important;
  }
}
