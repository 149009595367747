@import 'styles/component/slider-height-breakpoints';

.button_wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  background: #f1f2f4;
  color: #29394d;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.6rem 1.5rem;
  margin-right: 20px;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  @include large {
    padding: 0.5rem 1.5rem;
  }

  @include medium {
    padding: 0.4rem 1.5rem;
  }

  @include small {
    padding: 0.3rem 1.5rem;
    font-size: 16px;
  }

  &:hover {
    background: #d8dde2;
  }

  &.green {
    background: #06d9b1;
    color: #fff;

    &:hover {
      background: #02af8e;
    }
  }

  &.redOrange {
    background: #fb1e5b;
    color: #fff;

    &:hover {
      background: #cc1c4d;
    }
  }

  &.blue {
    background: #29394D;
    color: #fff;

    &:hover {
      background: #3f4d5e;
    }
  }
}
