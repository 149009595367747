@import 'styles/base/variables';

$pageContainerGap: 3rem;
$redOrange: #fb1e5b;
$greenTeal: #06D9B1;
$red: #fe3745;
$greenTealLight: #edfaf5;

.wrapper {
  height: $notifBarHeight;
  padding: 0 $pageContainerGap;

  &.redOrange {
    background-color: $redOrange;
  }

  &.greenTeal {
    background-color: $greenTeal;
  }

  &.red {
    background-color: $red;
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    flex: 1;
    -ms-flex: 1;

    &.greenTeal {
      color: $greenTealLight;

      .icon {
        svg{
          fill: white;
          & > g > path{
            fill: $greenTeal;
          }
        }
      }
    }

    &.redOrange {
      color: #fff;

      .icon {
        svg {
          fill: #fff;
        }
      }
    }

    &.red {
      color: #fff;

      .icon {
        svg {
          fill: #fff;
        }
      }
    }

    a{
      color: #a3d1f8;
      text-decoration-line: underline;
    }
  }

  .close_icon {
    text-align: end;

    svg {
      cursor: pointer;
    }
  }
}
