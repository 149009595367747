body {
  a {
    outline: none;
  }
  ul {
    list-style-type: none;
  }
}

.markdown {
  ul {
    margin-left: 1.25rem;
    list-style-type: disc;
  }

  ul,
  ol,
  dl {
    li {
      list-style: unset;
    }
  }
}
