.fetching-error {
  background-color: #eee;
  width: 100%;
  min-height: rem-calc(400px);
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  &__icon {
    font-size: 8rem;
    margin-bottom: 1rem;
  }

  &__title {
    font-family: "Montserrat";
    font-weight: bold;
    // color: #333;
  }
}
