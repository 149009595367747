@import "styles/base/variables";
@import "styles/base/functions";
@import "styles/base/mixins";

.container {
  background-color: #000;
  width: 100%;
  height: 43.5rem;
  margin: 0;
  background: url("/img/select-community-bg-2_new.jpg") no-repeat;
  color: #29394d;
  font-size: 1.5rem;
  background-size: cover;
  background-position: center;
  display: flex;

  p {
    margin-bottom: 1.5rem;
  }

  .number {
    font-size: 10rem;
    color: #fff;
  }
}

.column {
  height: 100%;
  width: calc(100% / 3);
}

.message {
  display: flex;
  width: calc(100% / 3 + 4rem);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  border-right: 2rem solid #fff;
  border-left: 2rem solid #fff;
  text-align: center;
  padding-top: 4rem;
}

.title {
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 1rem;
  b:first-child {
    color: #18a0fb;
  }
}

.description {
  font-size: 0.9rem;
}

.go_back {
}


.not_found_v2 {
  background: transparent;
  margin: 1rem auto;
  color: #2C394B;
  font-size: 1.5rem;
  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 64px;
      line-height: 72px;
    }
    h4 {
      font-size: 28px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 18px;
      color: #3F4E62
    }

    :global(.btn) {
      //min-width: 300px;
      a {
        text-transform: initial;
        letter-spacing: 1px;
        color: #18A0FB;
        padding-right: 10px;
      }
    }
  }
  &__text {
    display: flex;
    align-items: center;
  }
}
.not_found_v2_iframe{
  width: 750px;
}
