.onboarding {
  overflow: hidden;
}

.page {
  overflow-y: hidden;
  display: flex;
  flex: 1;
  border-radius: 5px;
}
