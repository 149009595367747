.infosModal {
  justify-content: center;
  align-items: center;
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f2f4;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 27px;
      width: 27px;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
  }
  h4 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #29394d;
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #18a0fb;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    &.blue {
      background: #18a0fb;

      &:hover {
        background: #2495e1;
      }
    }

    &.green {
      background: #06d9b1;

      &:hover {
        background: #02af8e;
      }
    }
  }
}
